// Copyright 2017 FOCUS Inc.All Rights Reserved.

/**
 * @fileOverview dev_4
 * @author sheny@made-in-china.com
 */

// Copyright 2017 FOCUS Inc.All Rights Reserved.

/**
 * @fileOverview dev_4
 * @author sheny@made-in-china.com
 */
if(typeof SILK !=='object'){
    var SILK = {};
}
// 判断doba域
SILK.isDoba = document.domain.indexOf('doba') !== -1;
SILK.UPLOAD_BASE_URL = SILK.isDoba ? '//upload.doba.com' : '//upload.crov.com';

if ($('#J-upload-domain').length > 0) {
    SILK.UPLOAD_BASE_URL = $('#J-upload-domain').val();
}
SILK.UPLOAD_FILE_URL = SILK.UPLOAD_BASE_URL + '/uploadFile';
SILK.ASYNC_ERROR = 'Oops! An Error Occurred.';
SILK.PROBE_TYPE = {
    formType: "FE",
    messageSend: "MS"
};
// global language set
SILK.LANG_MAPPING = {
    'en': 0,
    'zh-CN': 1
};
SILK.lang = 'en';

SILK.isMobile = function(customContent){
    customContent = customContent || 'PHONE';
    var screenType = window.getComputedStyle && window.getComputedStyle(document.body, ":after").getPropertyValue("content");
    return screenType && screenType.indexOf(customContent) !== -1;
};

SILK.loginParentLocationReload = function(callback){
    if (typeof(topLoginInfo) !== "undefined"){
        topLoginInfo.getInfo(function(data){
            if(!data.userName){
                window.location.reload();
            } else {
                callback && callback();
            }
        });
    }
};


(function(doc, win) {

    var DESIGN_SIZE = 360;
    var DESIGN_FONTSIZE = 36;
    var MEDIA_SIZES = [320,360,375,384,411,414,480,540];

    var docEl = doc.documentElement;
    var resizeEvt = 'orientationchange' in window ? 'orientationchange' : 'resize';
    var recalc = function() {
            var clientWidth = docEl.clientWidth;
            if (!clientWidth || clientWidth > MEDIA_SIZES[MEDIA_SIZES.length - 1] || MEDIA_SIZES.indexOf(clientWidth,0) !== -1) {
                return;
            }
            docEl.style.fontSize = DESIGN_FONTSIZE * (clientWidth / DESIGN_SIZE) + 'px';
        };
    if (!doc.addEventListener) return;
    win.addEventListener(resizeEvt, recalc, false);
    doc.addEventListener('DOMContentLoaded', recalc, false);


})(document, window);
// Copyright 2017 FOCUS Inc.All Rights Reserved.

/**
 * @fileOverview dev_4
 * @author sheny@made-in-china.com
 */

// template
var _templateResolve = (function() {
    // When customizing `templateSettings`, if you don't want to define an
    // interpolation, evaluation or escaping regex, we need one that is
    // guaranteed not to match.
    var noMatch = /(.)^/;

    // List of HTML entities for escaping.
    var entityMap = {
        '&': '&amp;',
        '<': '&lt;',
        '>': '&gt;',
        '"': '&quot;',
        "'": '&#x27;',
        '/': '&#x2F;'
    };

    // Certain characters need to be escaped so that they can be put into a
    // string literal.
    var escapes = {
        "'":		"'",
        '\\':		'\\',
        '\r':		'r',
        '\n':		'n',
        '\t':		't',
        '\u2028':	'u2028',
        '\u2029':	'u2029'
    };

    var escaper = /\\|'|\r|\n|\t|\u2028|\u2029/g;

    window.__htmlEscape = function(string) {
        if (string == null) return '';
        return ('' + string).replace(new RegExp('[&<>"/\']', 'g'), function(match) {
            return entityMap[match];
        });
    };

    // JavaScript micro-templating, similar to John Resig's implementation.
    // Underscore templating handles arbitrary delimiters, preserves whitespace,
    // and correctly escapes quotes within interpolated code.
    var template = function(text, data, settings) {
        if (!text) return;

        var render;
        settings = {
            evaluate		: /{{([\s\S]+?)}}/g,
            interpolate		: /{{=([\s\S]+?)}}/g,
            escape          : /{{-([\s\S]+?)}}/g
        };

        // Combine delimiters into one regular expression via alternation.
        var matcher = new RegExp([
                (settings.escape || noMatch).source,
                (settings.interpolate || noMatch).source,
                (settings.evaluate || noMatch).source
            ].join('|') + '|$', 'g');

        // Compile the template source, escaping string literals appropriately.
        var index = 0;
        var source = "__p+='";
        text.replace(matcher, function(match, escape, interpolate, evaluate, offset) {
            source += text.slice(index, offset)
                .replace(escaper, function(match) { return '\\' + escapes[match]; });

            if (escape) {
                source += "'+\n((__t=(" + escape + "))==null?'':__htmlEscape(__t))+\n'";
            }
            if (interpolate) {
                source += "'+\n((__t=(" + interpolate + "))==null?'':__t)+\n'";
            }
            if (evaluate) {
                source += "';\n" + evaluate + "\n__p+='";
            }
            index = offset + match.length;
            return match;
        });
        source += "';\n";

        // If a variable is not specified, place data values in local scope.
        if (!settings.variable) source = 'with(obj||{}){\n' + source + '}\n';

        source = "var __t,__p='',__j=Array.prototype.join," +
            "print=function(){__p+=__j.call(arguments,'');};\n" +
            source + "return __p;\n";

        try {
            render = new Function(settings.variable || 'obj', source);
        } catch (e) {
            e.source = source;
            throw e;
        }

        if (data) return render(data);
        var template = function(data) {
            return render.call(this, data);
        };

        // Provide the compiled function source as a convenience for precompilation.
        template.source = 'function(' + (settings.variable || 'obj') + '){\n' + source + '}';

        return template;
    };

    return template;
}());
void function (Global, $) {

    var isOss = function () {
        return !!$(".J-top-isOss").length;
    };

    var isLogon = function () {
        return !!$(".J-top-named").length;
    };

    var isJumpNext = function () {
        return !!$(".J-jumpNext").length;
    };

    var hasHeader = function() {
        return !!$('.J-header-wrap').length;
    };

    if (Global.topLoginInfo || !hasHeader()) {
        return;
    }

    var USER_TYPE = {
        SUPPLIER: "0",
        BUYER: "1"
    };
    var USER_INFO_LINK = SILK.isDoba ? '//login.doba.com/async/userInfo?jsoncallback=?' :'//login.crov.com/async/userInfo?jsoncallback=?';
    var CART_LIST_LINK = '//www.crov.com/shopping-cart/getHeaderShoppingCart?jsoncallback=?';

    var updateHeader = function (data) {
        //if (data.userType === USER_TYPE.SUPPLIER) {
        //    $('#J-top-cart').addClass('hidden');
        //} else {
        updateCartNum(data.cartNum);
        $('#J-top-cart').removeClass('hidden');
        updateCartList();
        //}
        negotiationListDisplay(data);
        updateBargainList(data.negoteNum);
        userList(data);
        topHeartDisplay(data);
        
        // 处理站内信
        notice(data);
        // productBlank(data.supportAll);
        if (!data.userName) {
            $(".J-top-unlogin").removeClass('hidden');
            $(".J-top-logged").addClass('hidden');
            return;
        }

        // 隐藏未登录
        $(".J-top-unlogin").addClass('hidden');
        // 显示登录信息
        $(".J-top-logged").removeClass('hidden');
        // 用户名
        var $userName = $(".J-top-username");
        if (!$userName.is(".J-top-named")) {
            $userName.text(data.userName || "");
        }



    };

    var ajaxUserInfo = null;
    var cacheUserInfo = null;
    var requestLoginInfo = function (params, callback) {
        // if ($(".J-top-logged").length === 0) {
        //     return;
        // }

        var data = {};
        var useless = $(".J-top-logged").attr("data-useless");

        if (useless) {
            data.useless = useless;
        }

        // 初始化会跳链接
        var localPage = encodeURIComponent(window.location.href);
        var signLink = $('.J-top-signIn').attr('href');
        var signOutLink = $('.J-top-signOut').attr('href');

        if (signLink && signLink.indexOf('nextPage=') === -1) {
            if (isJumpNext()) {
                $('.J-top-signIn').attr('href', $('.J-top-signIn').attr('href') + '?jumpNext=1&nextPage=' + localPage);
            } else {
                $('.J-top-signIn').attr('href', $('.J-top-signIn').attr('href') + '?nextPage=' + localPage);
            }
        }

        if (signOutLink && signOutLink.indexOf('nextPage=') === -1) {
            $('.J-top-signOut').attr('href', $('.J-top-signOut').attr('href') + '?nextPage=' + localPage);
        }

        $.extend(true, data, params);
        //ajaxUserInfo && ajaxUserInfo.abort();
        ajaxUserInfo = $.ajax({
            url: USER_INFO_LINK,
            dataType: "jsonp",
            type: 'get',
            data: data,
            timeout: 100 * 1000,
            success: function (json) {
                if (json && json.code === '10001') {
                    // data: userName userType (0:卖家 1:买家) cartNum
                    // data: dealerLevel (0:非经销商 1:经销商 2:todo准经销商(审核中))
                    if (!callback) {
                        updateHeader(json.data);
                    }
                    callback && callback(json.data);
                    cacheUserInfo = json.data;
                    ajaxUserInfo = null;
                    
                    if(json.data.isClearanceValid) {
                        $('.J-header-clearance').removeClass('hide');
                    }
                    // http://jira.vemic.com/browse/CROVPRJ-19320
                    // if (!$.cookie('showPaymentPasswordRemind') && json.data.showPaymentPasswordRemind) {
                    //     $.cookie('showPaymentPasswordRemind','showed',{ 
                    //         expires: 365,
                    //         path: "/", 
                    //         domain: "crov.com", 
                    //     });
                    //     artDialog.confirm("For the  the security of your account, please set payment password.", "Remind", {
                    //         text: "Set Now",
                    //         fn: function () {
                    //             window.location.href = '//login.crov.com/payment-password/verification';
                    //         }
                    //     }, {
                    //         text: "Set Later",
                    //         fn: function () {}
                    //     }, {
                    //         type: "tip"
                    //     });
                    // }
                }
            },
            error: function () {
                ajaxUserInfo = null;
            }
        });
    };

    // 处理站内信
    function notice(data) {
        if (!data) return;

        var hasSysMsg = false,
            hasQuoteMsgAg = false,//议价
            hasUserMsg = false,
            noticeData = data.notice || {},
            unReadNum = 0,
            moreUnreadNum = 0,
            countForMore = 0,
            countForShow = 5;

        if (noticeData.sysMsg) {
            hasSysMsg = true;
        }

        if (noticeData.userMsg && noticeData.userMsg.length > 0) {
            hasUserMsg = true;
            countForMore = noticeData.userMsg.length - countForShow;
            countForMore = (countForMore < 0 ? 0 : countForMore);

            for (var i = 0; i < noticeData.userMsg.length; i++) {
                unReadNum += parseInt(noticeData.userMsg[i]['unReadNum']);
                if( i > (countForShow-1) ){
                    moreUnreadNum += parseInt(noticeData.userMsg[i]['unReadNum']);
                }
            }
        }

        if (noticeData.quoteMsgAg) {
            hasQuoteMsgAg = true;
        }
       
        // 渲染页头下拉数据
        var $noticeList = $('#J-top-message-list'),
            renderData = {},
            template = $('#J-top-message-template').html();

        renderData.unReadNum = unReadNum;
        renderData.moreUnreadNum = moreUnreadNum;
        renderData.countForMore = countForMore;
        renderData.sysMsg = noticeData.sysMsg;
        renderData.userMsg = '';
        if (noticeData.userMsg) {
            renderData.userMsg = noticeData.userMsg.slice(0, countForShow);
        }

        $noticeList.html(_templateResolve(template, renderData));

        // vo 侧边栏 站内信提示处理
        var $messageTip = $('#J-sider-message-tip'),
            $orderMessageTip = $('#J-sider-message-order'),
            $systemMessageTip = $('#J-sider-message-system');

        $orderMessageTip.hide();
        $systemMessageTip.hide();
        $messageTip.hide();

        // 站内信
        if (hasSysMsg) {
            $("#J-top-message-num").addClass('no-num');
            $("#J-top-message-num").removeClass('hidden');
            $systemMessageTip.show();
            $messageTip.show();
        }
        if (hasUserMsg && unReadNum > 0) {
            $('#J-top-message-num .num').html(unReadNum);
            $("#J-top-message-num").removeClass('no-num').removeClass('hidden');
            $orderMessageTip.show();
            $messageTip.show();
        }

        if (!hasUserMsg && hasSysMsg) {
            var $linkBtn = $('.J-message');
            $linkBtn.attr('href', $linkBtn.attr('data-systemLink'));
        }
        if($('#J-sider-quote-ag-message-tip')[0]){
            //   侧边栏 议价新信息提示处理
            var $quoteMessageTip = $('#J-sider-quote-ag-message-tip'),
            $quoteSubMessageTip = $('#J-sider-message-quote');
            $quoteSubMessageTip.hide();
            $quoteMessageTip.hide();
            // 议价新信息
            if ( hasQuoteMsgAg ) {
            $quoteMessageTip.show();
            $quoteSubMessageTip.show();
            }
            // 议价新信息结束.  
        }

        //经销商带出来提示或者存在mesasge
        if(data.remind == '1' || hasSysMsg || hasUserMsg) {
            $('.J-top-remind-num').removeClass('hidden');
        }else{
            $('.J-top-remind-num').addClass('hidden');
        }
    }
    // 站内信结束


    //添加新商品是否新标签页与当前页打开
    // function productBlank(supportAll){
    //     if(!supportAll){
    //         $('.J-new-products').attr("target","_blank");
    //     }else{
    //         $('.J-new-products').removeAttr("target","_blank");
    //     }
    // }


    // 处理购物车
    var ajaxShoppingCart = null;
    function updateCartList() {
        ajaxShoppingCart && ajaxShoppingCart.abort();
        if ($('#J-top-cart-list-wrap').length > 0) {
            ajaxShoppingCart = $.ajax({
                url: CART_LIST_LINK,
                dataType: "jsonp",
                type: 'get',
                timeout: 100 * 1000,
                success: function (json) {
                    if (json && json.code === '10001') {
                        var dataList = json.data;
                        if (!dataList) {
                            throw 'get shopping cart list error.';
                            return;
                        }

                        var cartNum = dataList.length || 0,
                            countForMore = (dataList.length > 5 ? (dataList.length - 5) : 0);

                        var $cartListWrap = $('#J-top-cart-list-wrap'),
                            $cartList = $cartListWrap.find('#J-top-cart-list'),
                            renderData = {},
                            template = $('#J-top-cart-template').html();

                        renderData.cartNum = cartNum;
                        renderData.countForMore = countForMore;
                        renderData.cartList = dataList;

                        $cartList.html(_templateResolve(template, renderData));

                        updateCartNum(cartNum);
                    }
                    ajaxShoppingCart = null;
                },
                complete: function() {
                    ajaxShoppingCart = null;
                }
            });
        }
    }

    function updateCartNum(cartNum) {
        $("#J-top-cart-num").show();
        if (cartNum && cartNum > 0) {
            $("#J-top-cart-num .num").html(cartNum);
            $("#J-top-cart-num .num").removeClass('cart-num-none');
        } else {
            $("#J-top-cart-num .num").addClass('cart-num-none');
        }
    }
    // 购物车结束

    // 更新议价列表的数量
    function updateBargainList(bargainNumber) {
        var bargainNum = bargainNumber ? bargainNumber : 0;
        if(bargainNum > 0) {
            $('.J-bargain-number').addClass('curr-number')
        } else {
            $('.J-bargain-number').removeClass('curr-number')
        }
        $('.J-bargain-number').text(bargainNum);
    }

    // 是否经销商
    function isDealer() {
        return (cacheUserInfo.dealerLevel === '1');
    }

    // 是否准经销商 审核拒绝也算准经销商
    function isAssociateDealer() {
        return (cacheUserInfo.dealerLevel === '2' || cacheUserInfo.dealerLevel === '3');
    }

    function getInfo(callback) {
        requestLoginInfo("", callback);
    }


    // 默认执行一遍
    if (Global.top === window) {
        requestLoginInfo();
    }

    // exports
    Global.topLoginInfo = {
        update: updateHeader,
        request: requestLoginInfo,
        getInfo: getInfo,
        isDealer: isDealer,
        isAssociateDealer: isAssociateDealer,
        updateCartList: updateCartList
    };

    // user list
    function escapeHtml(string) {
        var entityMap = {
            "&": "&amp;",
            "<": "&lt;",
            ">": "&gt;",
            '"': '&quot;',
            "'": '&#39;',
            "/": '&#x2F;'
        };
        return String(string).replace(/[&<>"'\/]/g, function (s) {
            return entityMap[s];
        });
    }

    function userList(data) {
        if (data.userList && data.userList.listData && data.userList.listData.length > 0) {
            var listData = data.userList.listData;
            $('.J-header-user-list').show();
            var listHtml = ''
            for (var i=0; i<listData.length; i++) {
                listHtml += '<a class="item-con" href="'+listData[i].listUrl+'">' + escapeHtml(listData[i].listName) + ' <span class="number">(' + listData[i].itemNum + ')</span></a>'
            }
            $('.J-header-user-list-wrap').html(listHtml);
        } else {
            $('.J-header-user-list').hide();
        }
    }

    // Offer List 是否显示
    function negotiationListDisplay (data) {
        if (!data.showNegotiationList) {
            $('.J-header-nego-list').hide();
        }
    }

    // 页头爱心是否显示
    function topHeartDisplay (data) {
        if (!data.showNegotiationList && (!data.userList || !data.userList.listData || data.userList.listData.length <= 0)) {
            $('.J-top-heart').hide();
        }
    }


    // header视觉交互start---------------------------------------------------------------------------------------

    function lt1024(){
        var screenType = window.getComputedStyle && window.getComputedStyle($('.J-header-wrap')[0], ":before").getPropertyValue("content");
        return screenType && screenType.indexOf('LT1024') !== -1;
    }
    function toggleDrop($target,event){
        if(lt1024()){
            event.preventDefault();
            if ($target.hasClass('hover')) {
                $target.find('.header-drop-menu').addClass('out');
                setTimeout(function() {
                    $target.find('.header-drop-menu').removeClass('out');
                    $target.removeClass('hover');
                    $('.J-header-mask-m').removeClass('show');
                    $('body').css({'position':'static','overflow':'auto','width':'100%'});
                }, 320);
            } else {
                $target.addClass('hover');
                $('.J-header-mask-m').addClass('show');
                $('body').css({'position':'fixed','overflow':'hidden','width':'100%'});
            }
        }
    }

    $('.J-top-category').on('click','.J-top-icon', function(e){
        // 隐藏注册，登录下拉列表内容
        $('.J-top-unlogin,.J-top-logged').removeClass('hover');

        var $userMenu = $(this).parent();
        toggleDrop($userMenu,e);
    });
    $('.J-top-mask').on('click',function (e) {
        var $userMenu = $('.header-nav');
        toggleDrop($userMenu,e);
    })
    

    
    $('.J-header-mask-m').on('click',function (e) {
        var $userMenu = $('.header-nav.hover');
        $userMenu.find('.header-drop-menu').addClass('out');
        setTimeout(function() {
            $userMenu.find('.header-drop-menu').removeClass('out');
            $userMenu.removeClass('hover');
            $('.J-header-mask-m').removeClass('show');
            $('body').css({'position':'static','overflow':'auto','width':'100%'});
        }, 320);
    });

    $('.J-top-unlogin,.J-top-logged').on('click', '.J-top-icon', function (e) {

        // 隐藏目录
        $('.J-top-category').removeClass('hover');

        var $userMenu = $(this).parents('.header-nav');
        toggleDrop($userMenu,e);
        // 针对ipad下fixed元素不能通过样式撑开的处理
        if(lt1024()){
            $('.J-top-drop-menu').css('height', $(window).height() );
        }
    });

    $('.J-top-unlogin,.J-top-logged').on('mousemove', function (e) {
        // pc端高度自适应
        if(!lt1024()){
            $('.J-top-drop-menu').css('height', 'auto');
        }
    });

    // 议价小心心点击交互操作
    $('.J-top-bargain').on('click', '.J-top-icon', function(e) {
        var $userMenu = $(this).parents('.header-nav');
        toggleDrop($userMenu, e);
    });

    // 触屏端
    (function(){
        var st,
            ph, // 页面高度
            vh, // 可视区域高度
            timer = new Date().getTime(),
            _status = null, // 历史状态
            status = null, // 记录当前状态
            distance = 20, // 位移距离控制
            record = 0, // 开始滑动页面起点
            $headerWrap = $('.J-header-wrap'),
            headerWrapH = $headerWrap.height();

        st = $(document).scrollTop();
        ph = $(document).height();
        vh = $(window).height();
        toggleHeader(st);

        $(window).on('scroll', function() {
            st = $(document).scrollTop();
            if (new Date().getTime() - timer > 200) {
                record = st;
                ph = $(document).height();
                timer = new Date().getTime();
            }
            toggleHeader(st);
        });

        function toggleHeader(st) {
            if (st >= headerWrapH) {
                // 向上滑动
                if (record - st >= distance) {
                    status = 'up';
                } else if (st - record >= distance || (record < headerWrapH && st >= headerWrapH)) { // 向下滑动
                    status = 'down';
                }

                // 阻止 safari 滑动惯性到页面底部后回弹
                if (st >= ph - vh - distance * 5) {
                    status = 'down';
                }

                if (status === _status) {
                    return;
                }

                $headerWrap.addClass('fixed');
                if (status === 'up') {
                    $headerWrap.removeClass('scroll-out');
                    $headerWrap.addClass('scroll-in');
                }

                if (status === 'down' && $headerWrap.hasClass('scroll-in')) {
                    $headerWrap.removeClass('scroll-in');
                    $headerWrap.addClass('scroll-out');
                }

                _status = status;
            } else { // 页头置到原始位置为 nofixed
                // 只触发隐藏，没有up显示的情况下，向上滑动，移除fixed
                if ($headerWrap.hasClass('fixed') && !$headerWrap.hasClass('scroll-in')) {
                    $headerWrap.removeClass('fixed');
                }
                if (!_status) return;
                if (st <= 0) {
                    $headerWrap.removeClass('fixed scroll-out scroll-in');
                    _status = null;
                }
            }
        }
    })();

    // 经销商异常弹框提示开始
    var $dialogCoat = $('.J-top-ead-coat');
    $('.J-top-enter-account').on('click', function(e){
        e.preventDefault();
        $dialogCoat.show();
    });

    $dialogCoat.find('.J-btn-close,.J-btn-ok').on('click',function(e){
        $(this).parents('.J-top-ead-coat').hide();
    });
    // 经销商异常弹框提示结束

    // header视觉交互end----------------------------------------------------------------------

    //header关闭top notice ad
    if($('.J-header-top-close').length >0){
        $('.J-header-top-close').on('click',function (e) {
            e.preventDefault();
            $(this).closest('.J-header-top-notice').hide();
            $.cookie("topAdsHideFlag", "1", {
                expires: 1,
                domain:'crov.com',
                path:'/'
            })
        })
    }
    //header关闭top notice malaysia
    if($('.J-header-top-close-malaysia').length >0){
        $('.J-header-top-close-malaysia').on('click',function (e) {
            e.preventDefault();
            $(this).closest('.J-header-top-notice').hide();
            $.cookie("BFmalaysia", "1", {
                domain:'crov.com',
                path:'/'
            })
        })
    }

    /*ie浏览器头部提醒*/
    if($('.J-ie-header-notice').length > 0){
        var userAgent = navigator.userAgent;
        var ie = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1;
        ie && $('.J-ie-header-notice').show();
    }

    var headerNavLi = $('.J-header-nav li');
    headerNavLi.each(function () {
        var $this = $(this);
        var href = $this.find('a').attr('href');
        var windowHref = window.location.href.replace(/^http(s)?:/g,'');
        if(windowHref === href){
            $this.addClass('active');
        }
    })

  

}.call(this, this, this.jQuery || this.Zepto);
// Copyright 2017 FOCUS Inc.All Rights Reserved.

/**
 * @fileOverview dev_4
 * @author sheny@made-in-china.com
 */

$(function () {
    var forms = $('form[name=searchForm]');
    if (forms.length > 0) {
        forms.each(function (index, item) {
            (function (_form) {
                searchFormInit(_form);
            }(item));
        });
    }

    function searchFormInit(form) {
        var word = form.keyword;

        //<!-- Search Form -->
        $(form).bind('submit', function (e) {
            // 自定义搜索按钮跳转 (input输入框如果存在该属性并且为空则忽略为空判断，直接跳转)
            if ($(word).attr('data-customLink') && $.trim(word.value) === '') {
                window.location.href = $(word).attr('data-customLink');
                e.preventDefault();
                return false;
            }

            if ($.trim(word.value) === '') {
                window.location.reload();
                e.preventDefault();
                return false;
            }

            /*if (!/^[\x00-\x7F\xB0]*$/.test(word.value)) {
                alert('Please input your information in English.');
                e.preventDefault();
                return false;
            }*/
        });

        function createSubmitArgs(name, value) {
            var _input = document.createElement('input');
            _input.type = 'hidden';
            _input.name = name;
            _input.value = value;
            form.appendChild(_input);
        }

        function initArgsInputHidden(wrap, name, value) {
            if ($(wrap).find('input[name=' + name + ']').length > 0) {
                $(wrap).find('input[name=' + name + ']').remove();
            }

            $(wrap).append('<input type="hidden" name="' + name + '" value="' + value + '"/>');
        }

        var searchInput = function ($this) {
            if ($this.val() !== '') {
                $('.J-header-search-clear').addClass('active');
            } else {
                $('.J-header-search-clear').removeClass('active');
            }
        };
        $('.J-header-search-input').on('keyup', function () {
            searchInput($(this));
        }).on('focus', function () {
            searchInput($(this));
        });
    }
});
$(function () {
    /*
     * 搜索联想词
     */

    function IndexData(data) {
        if (!data) return;
        var i = 0;
        try {
            for (var template in data) {
                if (data[template] && data[template].length > 0) {
                    for (i = 0; i < data[template].length; i++) {
                        data[template][i]['_index'] = i;
                    }
                }
            }
        } catch (e) {
            window.console && console.log('init index error');
        }
    }

    /**
     * 执行模板js代码
     * @param data
     * @param str
     */
    function resolveTemplate(data, str) {
        var code = '',
            r_block = /<\$([\w\W]*?)\$>/gi,
            result = null,
            attr = null,
            transCode,
            tmpStr = str;
        for (attr in data) {
            transCode = data[attr];
            if (typeof transCode === 'string' && (transCode.indexOf('"') !== -1 || transCode.indexOf("'") !== -1)) {
                transCode = transCode.replace(/"/g, '\\"');
                transCode = transCode.replace(/'/g, "\'");
            }
            code += 'var ' + attr + '="' + transCode + '";';
        }
        while ((result = r_block.exec(str)) !== null) {
            var execCode = code;
            execCode += result[1];
            try {
                tmpStr = tmpStr.replace(result[0], new Function(execCode)() || '');
            } catch (e) {
                return '';
            }
        }
        return tmpStr;
    }

    function html2Elem(html) {
        var tmp = document.createElement('div');
        tmp.innerHTML = html;
        return tmp.childNodes[0];
    }

    /**
     * 转化模板为HTML
     * @param {Object} data
     * @param {String} keyword
     * @return {DocumentFragment}
     */
    function convertTemplates(data, keyword) {
        var _template = '<a href="javascript:;" val="<$ return word.replace(/"/g, "&quot;"); $>" J-code="{catCode}">{{word}}</a>';
        var template, item, i, l, t, node;
        var docfrag = document.createDocumentFragment();
        var list = '',
            highlightCls = 'bold';
        for (template in data) {
            if (data[template] && _template) {
                for (i = 0, l = data[template].length; i < l; i++) {
                    list = resolveTemplate(data[template][i], _template);
                    for (item in data[template][i]) {
                        list = list.replace(new RegExp('{' + item + '}', 'gi'), data[template][i][item]);
                    }
                    if (highlightCls) {
                        list = list.replace(new RegExp('({.+})', 'gi'), function ($1) {
                            $1 = $1.substring(1, $1.length - 1);
                            return $1.replace(new RegExp(keyword, 'gi'), createReplacer("$&", highlightCls));
                        });
                    }
                    node = html2Elem(list);
                    node.setAttribute('template', template);
                    docfrag.appendChild(node);
                }
            }
        }
        return docfrag;
    }

    function createReplacer(keyword, highlightCls) {
        return '<span class="' + highlightCls + '">' + keyword + '</span>';
    }
    //节流
    function _debounce() {
        var _timer = null;
        return function (func) {
            if (_timer) {
                clearTimeout(_timer);
                _timer = null;
            }
            _timer = setTimeout(function () {
                func();
            }, 300);
        }
    }
    var myDebounce = _debounce();
    var url = '';

    function isUaPhone() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    function searchWords() {
        url = '//keywordsuggestions.made-in-china.com/suggest/getEnProdSuggest.do?param=#param#&kind=5&ad=1&id=CROVSearchSuggest&count=10&ignoreCase=true&matchAnywhere=true&catflag=0&call=?';
        url += '&_' + new Date().getTime();
        $.ajax({
            type: 'get',
            url: url.replace('#param#', encodeURIComponent($(".J-header-search-input").val())),
            dataType: 'json',
            success: function (data) {
                $(".dropmenu-list").empty();
                IndexData(data);
                if (data['sug'] && data['sug'].length > 0) {
                    $(".dropmenu-list").append(convertTemplates(data, $(".J-header-search-input").val()));
                    //处理后端对于同一关键词不同类目时，显示多个相同词
                    $(".dropmenu-list a").each(function () {
                        if ($(this).text() == $(this).prev().text()) {
                            $(this).remove();
                        }
                    });
                    $(".dropmenu-list").prepend('<a style="display:none" class="hover" href="">'+$(".J-header-search-input").val()+'</a> ');
                    changeCoords();
                    $(".dropmenu-list").show();
                    //鼠标移动事件
                    $(".dropmenu-list a").hover(function () {
                        $(".dropmenu-list a").removeClass("hover");
                        $(this).addClass("hover");
                    });
                } else {
                    clearThinkWord();
                }
            },
            error: function () {
                clearThinkWord();
            }
        });
    }
    $(".J-header-search-input").on({
        "keyup": function (evt) {
            if (!$(".dropmenu-list")[0]) {
                $(".header-search-box").append('<div class="dropmenu-list"></div>');
            }
            var k = window.event ? evt.keyCode : evt.which;
            if (k == 13) {
                myDebounce(searchWords);
                return true;
            } 
            //不为空 && 不为上箭头或下箭头或回车或者左右键
            if ($(".J-header-search-input").val() != "" && k != 38 && k != 40 && k != 13&& k != 37&& k != 39) {
                myDebounce(searchWords);
            } else if (k == 38) { //上箭头
                $(".dropmenu-list a.hover").prev().addClass("hover");
                $(".dropmenu-list a.hover").next().removeClass("hover");
                $('.J-header-search-input').val($('.dropmenu-list a.hover').text());
            } else if (k == 40) { //下箭头
                $(".dropmenu-list a.hover").next().addClass("hover");
                $(".dropmenu-list a.hover").prev().removeClass("hover");
                $('.J-header-search-input').val($('.dropmenu-list a.hover').text());
            } else if (k == 13) { //回车
                form.submit();
                clearThinkWord();
            }else  if (k == 37||k == 39) { //左右键
                return true;
            } else {
                clearThinkWord();
            }
        },
        "focus": function (e) {
            if($('.J-header-search-input').val() !== ''){
                myDebounce(searchWords);
            }
        }

    });

    if (('ontouchend' in document) && isUaPhone()) {
        $(".J-header-search-input").on({
            "touchend": function (e) {
                if ($(".dropmenu-list a").length > 0) {
                    changeCoords();
                    $(".dropmenu-list").show();
                };
            }
        });
        $("body").on("touchstart", function (e) {
            $('.dropmenu-list').hide();
        });
    } else {
        $(".J-header-search-input").on({
            "click": function (e) {
                e.stopPropagation();
                return false;
            },
            "blur": function (e) {
                clearThinkWord();
            }
        });
       
    };
    var sy = 0;
    if ($('.J-header-search-input')[0]) {
        var form = $('.header-search-box');
        var startx, starty, endx, endy;
        $(".header-search-box").append('<div class="dropmenu-list"></div>');
        $(".dropmenu-list").hide();
        changeCoords(); //控制查询结果div坐标
        $(".dropmenu-list").on('mousedown', function (e) {
            e.preventDefault();
        }).on('click', 'a', function () {
            $(".J-header-search-input").val(this.getAttribute('val'));
            form.submit();
            clearThinkWord();
        }).on("touchstart", 'a', function (e) {
            var touch = e.originalEvent.targetTouches[0];
            sy = touch.pageY;
            startx = touch.pageX;
            starty = touch.pageY;
            endx = startx;
            endy = starty;
            e.stopPropagation();
        }).on("touchmove", 'a', function (e) {
            var touch = e.originalEvent.targetTouches[0];
            endx = touch.pageX;
            endy = touch.pageY;
            e.stopPropagation();
            //ios移动端滑动问题解决
            var down = (touch.pageY - sy > 0);
            //top
            if (down && this.parentNode.scrollTop <= 0) {
                e.preventDefault();
            }
            //bottom
            if (!down && this.parentNode.scrollTop >= this.parentNode.scrollHeight - this.parentNode.clientHeight) {
                e.preventDefault();
            }
        }).on("touchend", 'a', function (e) {
            e.stopPropagation();
            var x = endx - startx;
            var y = endy - starty;
            var W = x < 0 ? x * -1 : x; //x轴的滑动值, w为x的绝对值
            var H = y < 0 ? y * -1 : y; //y轴的滑动值
            if (W < 5 && H < 5) {
                e.preventDefault();
                $(".J-header-search-input").val(this.getAttribute('val'));
                form.submit();
                clearThinkWord();
            }
        });
    }
    //解决页头滚动时影响
    $(window).on('scroll', function() {
        if($('.J-header-search-input')[0]&&$(".dropmenu-list").css('display')=="block"){
            if($('.J-header-wrap').hasClass('scroll-out')||($('.J-header-wrap').hasClass('fixed')&&!$('.J-header-wrap').hasClass('scroll-in'))){
                $(".dropmenu-list").hide();
                $(".J-header-search-input").blur();
            }
        }
    });

    $(window).on('resize', function () {
        changeCoords();
    })
    //清空联想词
    function clearThinkWord() {
        $(".dropmenu-list").empty();
        $(".dropmenu-list").hide();
    }
    //设置查询结果div坐标
    function changeCoords() {
        $('.dropmenu-list').css({
            "width": $(".J-header-search-input").innerWidth() - 56,
            "left": $(".header-search-box").css("padding-left"),
        });
    }
    //中文字符提示
    $(form).bind('submit', function (e) {
        if (!/^[\x00-\x7F]*$/.test($('.J-header-search-input').val())) {
            alert('Please input the information in English only.');
            e.preventDefault();
            return false;
        }
    });
    $('.J-header-search-clear').on('click', function () {
        $('.J-header-search-input').val('');
        $(this).removeClass('active');
        clearThinkWord();
    })
});
$(function() {
    var headerCatePopupHandel = {
        firstCateIndex: 0,
        secondCateIndex: 0,
        popupSettimout: null,
        popupSettimout2: null,

        $cate1st: $('.J-cate-1st'),
        $cate2nd: $('.J-cate-2nd'),
        $cate3rd: $('.J-cate-3rd'),
        $cateGroup2nd: $('.J-cate-2nd .cate-group'),
        $cateGroup3rd: $('.J-cate-3rd .cate-group'),

        mouseLocs1: [],
        mouseLocs2: [],

        init: function() {
            this._showCatePopup();
            this._showCateSub();
            this._showCateMore();
        },
        _showCatePopup: function() {
            var _this = this;
            $('.J-header-cate-name').on('mouseenter', function(){
                var $this = $(this);
                if (window.innerWidth <= 1023) {
                    return false;
                }
                clearTimeout(_this.popupSettimout);
                $('.J-cate-1st').addClass('hide');
                _this.firstCateIndex = $this.index();
                _this.secondCateIndex = 0;
                var left = $this.offset().left;

                var wrapperWidth = $('.header-bottom-cnt').width();
                var wrapperLeft = $('.header-bottom-cnt').offset().left;

                if (left + 642 > wrapperLeft + wrapperWidth) {
                    left = wrapperLeft + wrapperWidth - 662;
                }
                var top = $('.J-header-wrap').height();

                _this.popupSettimout2 = setTimeout(function () {
                    $('.J-header-category-list').removeClass('hide').css({left:left, top: top, right:'auto'}).find('.hover').removeClass('hover');
                    $('.header-bottom-cnt').find('.hover').removeClass('hover');
                    $this.find('a').addClass('hover');
                    _this.__showCate2nd();
                    _this.__showCate3rd();
                },100);

            }).on('mouseleave', function() {
                if (window.innerWidth <= 1023) {
                    return false;
                }

                _this.popupSettimout = setTimeout(function(){
                    $('.J-header-category-list').addClass('hide');
                    $('.header-bottom-cnt').find('.hover').removeClass('hover');
                }, 100);
                clearTimeout(_this.popupSettimout2);

            });

            $('.J-header-category-list').on('mouseenter', function(){
                if (window.innerWidth <= 1023) {
                    return false;
                }
                clearTimeout(_this.popupSettimout);
            }).on('mouseleave', function(){
                if (window.innerWidth <= 1023) {
                    return false;
                }
                $('.J-header-category-list').addClass('hide');
                $('.header-bottom-cnt').find('.hover').removeClass('hover');
            })
        },
        _showCateSub: function(){
            var _this = this;
            $('.J-cate-1st .cate-item').on('mouseover', function(e){
                if (window.innerWidth <= 1023) {
                    return false;
                }
                if($(this).hasClass('hover')) {
                    return;
                }

                var currentCate = e.target;
                var $this = $(this);
                currentCate.timeouter = null;


                var moveAngle;

                if (_this.mouseLocs1.length < 3) {
                    moveAngle = 90;
                } else {
                    moveAngle = _this.__getAngle(_this.mouseLocs1[0], _this.mouseLocs1[2]);
                }
                
                if (moveAngle < 60) {
                    currentCate.timeouter = setTimeout(function () {
                        $this.siblings('.cate-item').removeClass('hover')
                        $this.addClass('hover')
                        _this.firstCateIndex = $this.index();
                        _this.secondCateIndex = 0;
                        _this.__showCate2nd()
                        _this.__showCate3rd()
                    }, 100)
                } else {
                    $this.siblings('.cate-item').removeClass('hover')
                    $this.addClass('hover')
                    _this.firstCateIndex = $this.index();
                    _this.secondCateIndex = 0;
                    _this.__showCate2nd()
                    _this.__showCate3rd()
                }
            }).on('mousemove', function(e){
                if (window.innerWidth <= 1023) {
                    return false;
                }
                var x = e.clientX, 
                    y = e.clientY;
                

                _this.mouseLocs1.push({x: x, y: y});
        
                if (_this.mouseLocs1.length > 3) {
                    _this.mouseLocs1.shift();
                }
            }).on('mouseout', function(e) {
                if (window.innerWidth <= 1023) {
                    return false;
                }
                if (e.target.timeouter) {
                    clearTimeout(e.target.timeouter);
                }
            })

        
            $('.J-cate-2nd .cate-item').on('mouseover', function(e){
                if (window.innerWidth <= 1023) {
                    return false;
                }
                if($(this).hasClass('hover')) {
                    return;
                }

                var currentCate = e.target;
                var $this = $(this);
                currentCate.timeouter = null;

                var moveAngle;

                if (_this.mouseLocs2.length < 3) {
                    moveAngle = 90;
                } else {
                    moveAngle = _this.__getAngle(_this.mouseLocs2[0], _this.mouseLocs2[2]);
                }
                if (moveAngle < 60) {
                    currentCate.timeouter = setTimeout(function () {
                        $this.siblings('.cate-item').removeClass('hover')
                        $this.addClass('hover')
                        _this.secondCateIndex = $this.index();
                        _this.__showCate3rd()
                    }, 100)
                } else {
                    $this.siblings('.cate-item').removeClass('hover')
                    $this.addClass('hover')
                    _this.secondCateIndex = $this.index();
                    _this.__showCate3rd()
                }
            }).on('mousemove', function(e){
                if (window.innerWidth <= 1023) {
                    return false;
                }
                var x = e.clientX, 
                    y = e.clientY;
                
                _this.mouseLocs2.push({x: x, y: y});
        
                if (_this.mouseLocs2.length > 3) {
                    _this.mouseLocs2.shift();
                }
            }).on('mouseout', function(e) {
                if (window.innerWidth <= 1023) {
                    return false;
                }
                if (e.target.timeouter) {
                    clearTimeout(e.target.timeouter);
                }
            })

        },

        _showCateMore: function(){
            var _this = this;
            $('.J-header-cate-more').on('mouseenter', function(){
                $('.header-bottom-cnt').find('.hover').removeClass('hover');
                $(this).find('a').addClass('hover');
                clearTimeout(_this.popupSettimout);
    
                var cateLength = $('.J-header-cate-name').length;
                var wrapCateLength = $('.J-header-cate-name.hide').length;
                var wrapCateIndex = cateLength-wrapCateLength;
                
                var left = $(this).offset().left;
                var top = $('.J-header-wrap').height();

                
                var wrapperWidth = $('.header-bottom-cnt').width();
                var wrapperLeft = $('.header-bottom-cnt').offset().left;
                var left = wrapperLeft + wrapperWidth - 982;

                _this.popupSettimout2 = setTimeout(function () {
                    $('.J-header-category-list').removeClass('hide').css({left: left, top:'131px'}).find('.hover').removeClass('hover');

                    $('.J-cate-1st').removeClass('hide').find('.cate-item').eq(wrapCateIndex).addClass('hover');
                    $('.J-cate-1st').find('.hide').removeClass('hide');
                    for (var i=0; i<wrapCateIndex; i++) {
                        $('.J-cate-1st').find('.cate-item').eq(i).addClass('hide');
                    }
                    _this.firstCateIndex = wrapCateIndex;
                    _this.__showCate2nd();
                    _this.__showCate3rd();
                },100);


            }).on('mouseleave', function(){
                _this.popupSettimout = setTimeout(function(){
                    $('.J-header-category-list').addClass('hide');
                    $('.header-bottom-cnt').find('.hover').removeClass('hover');
                }, 100);
                clearTimeout(_this.popupSettimout2);

            })
        },

        __showCate2nd: function (firstCateIndex) {
            var firstCateIndex = firstCateIndex || this.firstCateIndex;
            $('.J-cate-2nd').removeClass('hide');
            $('.J-cate-2nd .cate-group').addClass('hide');
            $('.J-cate-2nd .cate-group').eq(firstCateIndex).removeClass('hide');
        },
        __showCate3rd: function (firstCateIndex, secondCateIndex) {
            var firstCateIndex = firstCateIndex || this.firstCateIndex;
            var secondCateIndex = secondCateIndex || this.secondCateIndex;
            $('.J-cate-2nd .cate-group').eq(firstCateIndex).find('.cate-item').removeClass('hover').eq(secondCateIndex).addClass('hover');
            $('.J-cate-3rd').removeClass('hide');
            $('.J-cate-3rd .cate-group').addClass('hide');
            $('.J-cate-3rd .cate-group').eq(firstCateIndex).removeClass('hide');
            $('.J-cate-3rd .cate-group').eq(firstCateIndex).find('.cate-group-sub').addClass('hide');
            $('.J-cate-3rd .cate-group').eq(firstCateIndex).find('.cate-group-sub').eq(secondCateIndex).removeClass('hide');
        },
        __getAngle: function(start, end) {
            var diff_x = end.x - start.x,
            diff_y = end.y - start.y;
            //返回角度,不是弧度
            return Math.abs(360 * Math.atan(diff_y / diff_x) / (2 * Math.PI));
        }
    }
    headerCatePopupHandel.init();


    var cateItemWidthArr = [];

    function cateWrap() {
        if (window.innerWidth <= 1023) {
            return false;
        }
        if (cateItemWidthArr.length === 0) {
            for (var i=0; i<$('.J-header-cate-name').length; i++) {
                cateItemWidthArr.push($('.J-header-cate-name').eq(i).width())
            }
        }
        var cateWrapWidth = $('.header-bottom-inner').width();

        var cateWrapWidthLeft = cateWrapWidth - $('.category-more').width() - 92;
        var $cateItem = $('.J-header-cate-name');
        var cateItemWidth = 0;
        for (var i=0; i<$cateItem.length; i++) {
            cateItemWidth += cateItemWidthArr[i];
            if (cateItemWidth <= cateWrapWidthLeft) {
                $cateItem.eq(i).removeClass('hide');
            } else if (cateItemWidth > cateWrapWidthLeft) {
                for(;i<$cateItem.length; i++) {
                    $cateItem.eq(i).addClass('hide');
                }
                break;
            }
        }
    }

    cateWrap();

    $(window).on('resize', function(){
        cateWrap();
        if(window.innerWidth >= 1024 && $('.header-bottom-wrap').hasClass('active')) {
            $('.J-header-cate-close').trigger('click');
        }
    });

    var headerSideCateHandel = {
        stage: 0,
        firstCateIndex: 0,
        secondCateIndex: 0,
        $cate1st: $('.J-cate-1st'),
        $cate2nd: $('.J-cate-2nd'),
        $cate3rd: $('.J-cate-3rd'),
        $cateGroup2nd: $('.J-cate-2nd .cate-group'),
        $cateGroup3rd: $('.J-cate-3rd .cate-group'),
        init: function () {
            this._showCate();
            this._showCate2nd();
            this._showCate3rd();
            this._active();
            this._goBack();
            //this._close();

            var _this = this;

            $('.J-header-cate-close, .J-header-mask-m').on('click', function() {
                _this._close();
            })
        },

        _showCate: function () {
            var _this = this;
            $('.J-side-menu-cate').on('click', function() {
                $('.J-header-side-cate').addClass('hide');
                $('.J-header-category-list').removeClass('hide');
                $('.J-cate-1st').removeClass('hide');
                _this.stage = 1;

                $('.J-header-menu-back').removeClass('hide');
                $('.J-header-side-menu-title').text('All Products');
                return false;
            })
            $('.J-side-menu-promotion').on('click', function() {
                $('.J-header-side-cate').addClass('hide');
                $('.J-header-promotion-list').removeClass('hide');
                _this.stage = 1;

                $('.J-header-menu-back').removeClass('hide');
                $('.J-header-side-menu-title').text('Promotions');
                return false;
            })
            $('.J-side-menu-resellerRecruited').on('click', function() {
                $('.J-header-side-cate').addClass('hide');
                $('.J-header-resellerRecruited-list').removeClass('hide');
                _this.stage = 1;

                $('.J-header-menu-back').removeClass('hide');
                $('.J-header-side-menu-title').text('Resellers Wanted');
                return false;
            })
        },

        _showCate2nd: function () {
            var _this = this;
            $('.J-cate-1st .cate-item').on('click', function(){
                var isLastLayer = $(this).hasClass('cate-last-layer');
                if (window.innerWidth <= 1023 && !isLastLayer) {
                    _this.firstCateIndex = $(this).index();

                    _this.$cate1st.addClass('hide');
                    _this.$cate2nd.removeClass('hide');
                    _this.$cateGroup2nd
                        .addClass('hide')
                        .eq(_this.firstCateIndex)
                        .removeClass('hide');

                    $('.J-header-side-menu-title').text($(this).text());

                    _this.stage = 2;
                    
                    return false;
                }
            });
        },

        _showCate3rd: function () {
            var _this = this;
            $('.J-cate-2nd .cate-item').on('click', function(){
                var isLastLayer = $(this).hasClass('cate-last-layer');
                if (window.innerWidth <= 1023 && !isLastLayer) {
                    _this.secondCateIndex = $(this).index();
                    _this.$cate2nd.addClass('hide');
                    _this.$cate3rd.removeClass('hide');
                    _this.$cateGroup3rd.addClass('hide');
                    _this.$cateGroup3rd
                        .eq(_this.firstCateIndex)
                        .removeClass('hide')
                        .find('.cate-group-sub')
                        .addClass('hide')
                        .eq(_this.secondCateIndex)
                        .removeClass('hide');
                    
                    $('.J-header-side-menu-title').text($(this).text());
                    _this.stage = 3;
                    
                    return false;
                }
            });
        },

        _active: function () {
            var _this = this;
            $('.J-header-side-menu-icon').on('click', function() {
                _this.__init();
                var windowHeight = $(window).height();
                $('.header-bottom-wrap').addClass('active').height(windowHeight);
                $('.J-header-side-cate').removeClass('hide');
                $('.J-header-mask-m').addClass('show');
                $('body').addClass('body-freeze');
                $('.J-header-category-list').height(windowHeight - 62);
            })
        },

        _goBack: function () {
            var _this = this;
            $('.J-header-menu-back').on('click', function() {
                if (_this.stage === 1) {
                    $('.J-header-side-cate').removeClass('hide');
                    $('.J-cate-1st').addClass('hide');
                    $('.J-header-category-list').addClass('hide');
                    $('.J-header-promotion-list').addClass('hide');
                    $('.J-header-resellerRecruited-list').addClass('hide');
                    _this.stage = 0;
                    $('.J-header-menu-back').addClass('hide');
                    $('.J-header-side-menu-title').text('Menu');
                } else if (_this.stage === 2) {
                    _this.$cate1st.removeClass('hide');
                    _this.$cate2nd.addClass('hide');
                    _this.$cateGroup2nd.addClass('hide');
                    $('.J-header-side-menu-title').text('All Products');
                    _this.stage = 1;

                } else if (_this.stage === 3) {
                    _this.$cate2nd.removeClass('hide');
                    _this.$cateGroup2nd
                        .addClass('hide')
                        .eq(_this.firstCateIndex)
                        .removeClass('hide');
                    _this.$cate3rd.addClass('hide');
                    _this.$cateGroup3rd.addClass('hide');
                    _this.$cateGroup3rd.find('.cate-group-sub').addClass('hide');
                    
                    var cateName = $('.J-cate-1st .cate-item').eq(_this.firstCateIndex).text();
                    $('.J-header-side-menu-title').text(cateName);

                    _this.stage = 2;
                }
                
                return false;
            })
        },

        _close: function() {
            $('.header-bottom-wrap').removeClass('active');
            $('.J-header-category-list').height('auto');
            $('.J-header-mask-m').removeClass('show');
            $('body').removeClass('body-freeze');
            setTimeout(function(){
                $('.header-bottom-wrap').height('auto');
                $('.J-header-category-list').height('auto');
            },500);
            this.__init();
            return false;
        },
        __init: function() {
            $('.J-header-menu-back').addClass('hide');
            $('.J-header-side-menu-title').text('Menu');
            $('.J-header-side-cate').removeClass('hide');
            $('.J-header-category-list').addClass('hide');
            $('.J-header-promotion-list').addClass('hide');
            $('.J-header-resellerRecruited-list').addClass('hide');
            $('.J-header-category-list').find('.hover').removeClass('hover');
            this.$cate1st.addClass('hide');
            this.$cate1st.find('.hide').removeClass('hide');
            this.$cate2nd.addClass('hide');
            this.$cate3rd.addClass('hide');
            this.$cateGroup2nd.addClass('hide');
            this.$cateGroup3rd.addClass('hide');
            this.$cateGroup3rd.find('.cate-group-sub').addClass('hide');
        }
    };
    headerSideCateHandel.init();

    //footer
    $('.J-footer-title').on('click', function () {
        var $this = $(this);
        var next = $this.next('.J-footer-group');
        if(next.css('display') === 'none'){
            next.slideDown();
            $this.find('.ob-icon').addClass('icon-deduct');
            $this.addClass('wapShow');
        }else if($this.hasClass('wapShow')){
            next.slideUp();
            $this.find('.ob-icon').removeClass('icon-deduct');
            $this.removeClass('wapShow');
        }

    })

});


(function(){ 
    var categoryPCTimer = null;
    var hasHover = false;
    var pre = null;
    function angle(start, end) {
        var diff_x = end.x - start.x,
            diff_y = end.y - start.y;
        if (diff_x <= 0) {
            return 90
        }
        //返回角度,不是弧度
        return 360 * Math.atan(diff_y / diff_x) / (2 * Math.PI);
    }
    $(".J-categoryPC").hover(function (e) {
        $(".J-categoryPC-level1").show();
    },function () {
        hasHover = false;
        categoryPCTimer = null;
        pre = null;
        $(".J-categoryPC-level1,.J-categoryPC-level2,.J-categoryPC-level3").hide();
        $(".J-categoryPC-item").removeClass("hover");
    });

    $(".J-categoryPC-level1,.J-categoryPC-level2,.J-categoryPC-level3").hover(function (e) {
        hasHover = false;
    },function (e) {
    })
    $(".J-categoryPC-item").on('mousemove',function (e) {
        if ($(this).hasClass("hover")) {
            pre = { x: e.clientX, y: e.clientY };
        } else {
        }
    })
    function showLevel2() {
        var catId = $(this).attr("catId");
        $(".J-categoryPC-item").removeClass("hover");
        $(this).addClass('hover')
        if($(this).find(".icon-right").length > 0){
            $(".J-categoryPC-level2").show();
        } else {
            $(".J-categoryPC-level2").hide();
        }
        $(".J-categoryPC-level3").hide();
        $(".J-categoryPC-level2 > [catId]").hide();
        $(".J-categoryPC-level2 > [catId=" + catId +"]").show();
    }
    function itemHover(e,callback) {
        var that = this;
        if (pre) {
            var moveAngle = Math.abs(angle(pre, { x: e.clientX, y: e.clientY }));
        }
        if (!hasHover || moveAngle > 60) {
            hasHover = true;
            callback.call(this);
        } else {
            if (!categoryPCTimer) {
                categoryPCTimer = setTimeout(function() {
                    clearTimeout(categoryPCTimer);
                    categoryPCTimer = null;
                    callback.call(that);
                }, 150);
            }
        }
    }
    $(".J-categoryPC-level1 .J-categoryPC-item").hover(function (e) {
        itemHover.call(this,e,showLevel2);
    },function (e) {
        clearTimeout(categoryPCTimer);
        categoryPCTimer = null;
    })
    function showLevel3() {
        var catId = $(this).attr("catId");
        $(".J-categoryPC-level2 .J-categoryPC-item").removeClass("hover");
        $(this).addClass('hover');
        if($(this).find(".icon-right").length > 0){
            $(".J-categoryPC-level3").show();
        } else {
            $(".J-categoryPC-level3").hide();
        }
        $(".J-categoryPC-level3 > [catId]").hide();
        $(".J-categoryPC-level3 > [catId=" + catId +"]").show();
    }
    $(".J-categoryPC-level2 .J-categoryPC-item").hover(function (e) {
        itemHover.call(this,e,showLevel3);
    },function (e) {
        clearTimeout(categoryPCTimer);
        categoryPCTimer = null;
    })
    $(".J-categoryPC-level3 .J-categoryPC-item").hover(function (e) {
        $(".J-categoryPC-level3 .J-categoryPC-item").removeClass("hover");
        $(this).addClass('hover');
    },function (e) {
    })
})()
$(function() {
    function isUaPhone() {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }

    var tap = ('ontouchend' in document) && isUaPhone() ? 'touchend' : 'click';

    var $headerShipWrap = $('.J-header-ship-to-wrap');
    var $headerShip = $('.J-header-ship-to');
    var $headerShipMWrap = $('.J-header-ship-to-m-wrap');
    var $headerShipM = $('.J-header-ship-to-m');
    var $headerShipDialog = $('.J-header-ship-dialog');
    var $headerShipDialogM = $('.J-header-ship-dialog-m');

    var originCountry = $headerShipDialog.find('select[name=country]').val();
    var originCountryM = $headerShipDialogM.find('select[name=country]').val();
    var originZip = $headerShipDialog.find('.J-zip-text').text();
    var originZipM = $headerShipDialogM.find('.J-zip-text').text();

    var clickClose = function($wrap, originCountry, originZip) {
        if(originCountry === '') {
            $wrap.find('select[name=country]').val('');
            $wrap.find('.J-zip-text').text(originZip);
            $wrap.find('.J-zip-input-wrap').hide();
            $wrap.find('.J-zip-text-wrap').show();
        } else {
            $wrap.find('select[name=country]').val(originCountry);
            $wrap.find('.J-zip-text').text('');
            $wrap.find('.J-zip-input-wrap').show();
            $wrap.find('.J-zip-text-wrap').hide();
        }
    };

    var bodyClose = function() {
        $('body').removeClass('body-overflow');
        $('html').css({
            overflow: ""
        });
    };

    var checkZip = function($wrap) {
        var zip = $wrap.find('input[name=headerShipZip]');
        var error = $wrap.find('.J-header-ship-zip-error');
        var country = $wrap.find('select[name=country]');
        var goBtn = $wrap.find('.J-header-ship-confirm');
        if(zip.val() === '') {
            error.text('Please enter zip code.');
            zip.addClass('error');
        } else if(!/^(\d{5}(?:\-?\d{4})?)$/.test($.trim(zip.val()))) {
            error.text('Please enter a valid zip code of contiguous United States.');
            zip.addClass('error');
        } else {
            if(!goBtn.hasClass('disabled')) {
                $.ajax({
                    dataType: 'jsonp',
                    jsonp: "jsoncallback",
                    type: 'GET',
                    url: '//www.crov.com/address/ships-to/save',
                    data: {
                        needSave: false,
                        locationType: '1',
                        zipCode: zip.val()
                    },
                    cache: false,
                    beforeSend: function() {
                        goBtn.addClass('disabled');
                    },
                    success: function(response){
                        if(response.code === '10001') {
                            $('.J-zip-text').text(zip.val() + ', U.S.');
                            error.text('');
                            zip.removeClass('error');
                            // zip.val('');
                            $('.J-zip-input-wrap').hide();
                            $('.J-zip-text-wrap').show();
                        } else {
                            error.text('Please enter a valid zip code of contiguous United States.');
                            zip.addClass('error');
                        }
                    },
                    complete: function() {
                        goBtn.removeClass('disabled');
                    }
                })
            }
        }
        country.val('');
    };

    var changeCountry = function($wrap) {
        var zip = $wrap.find('input[name=headerShipZip]');
        zip.val('');
        zip.removeClass('error');
        $wrap.find('.J-header-ship-zip-error').text('');
        $wrap.find('.J-zip-input-wrap').show();
        $wrap.find('.J-zip-text-wrap').hide();
    };

    $headerShipWrap.on(tap, function() {
        $headerShipDialog.addClass('show');
        $('.J-header-ship-mask').addClass('show');
        //pc 初始化弹窗时清空输入框和错误提示
        var zip = $headerShipDialog.find('input[name=headerShipZip]');
        zip.val('');
        zip.removeClass('error');
        $headerShipDialog.find('.J-header-ship-zip-error').text('');
    });

    $('.J-close-header-ship').on(tap, function() {
        $headerShipDialog.removeClass('show');
        $('.J-header-ship-mask').removeClass('show');
    });


    // 点击confirm按钮
    $('.J-header-ship-confirm, .J-fd-ok').on(tap, function() {
        var _this = $(this);
        if (_this.is('[disabled]')) {
            return false;
        }
        _this.attr('disabled', 'disabled');
        var $wrap

        if($(window).width() >= 768) {
            $wrap = $headerShipDialog
        } else {
            $wrap = $headerShipDialogM
        }

        var zip = $wrap.find('input[name=headerShipZip]');
        var error = $wrap.find('.J-header-ship-zip-error');
        var country = $wrap.find('select[name=country]');

        error.text('');
        zip.removeClass('error');
        if (zip.val() !== '') {
            if (!/^(\d{5}(?:\-?\d{4})?)$/.test($.trim(zip.val()))) {
                error.text('Please enter a valid zip code of contiguous United States.');
                zip.addClass('error');
                
                _this.removeAttr('disabled')
            } else {
                if(!zip.is('[disabled]')) {
                    $.ajax({
                        dataType: 'jsonp',
                        jsonp: "jsoncallback",
                        type: 'GET',
                        url: '//www.crov.com/address/ships-to/save',
                        data: {
                            needSave: false,
                            locationType: '1',
                            zipCode: zip.val()
                        },
                        cache: false,
                        beforeSend: function() {
                            zip.attr('disabled', 'disabled');
                        },
                        success: function(response){
                            if(response.code === '10001') {
                                // $('.J-zip-text').text(zip.val() + ', U.S.');

                                // error.text('');
                                // zip.removeClass('error');
                                // zip.val('');
                                // $('.J-zip-input-wrap').hide();
                                // $('.J-zip-text-wrap').show();

                                var data= {
                                    needSave: true,
                                    locationType: '1',
                                    zipCode: zip.val()
                                }


                                $.ajax({
                                    dataType: 'jsonp',
                                    jsonp: "jsoncallback",
                                    type: 'GET',
                                    url: '//www.crov.com/address/ships-to/save',
                                    data: data,
                                    cache: false,
                                    success: function(){
                                        window.location.reload();
                                        $headerShipDialog.removeClass('show');
                                        $('.J-header-ship-mask').removeClass('show');
                                    },
                                    error: function() {
                                        zip.removeAttr('disabled')
                                        _this.removeAttr('disabled')
                                    }
                                })
    
                            } else {
                                error.text('Please enter a valid zip code of contiguous United States.');
                                zip.addClass('error');
                                zip.removeAttr('disabled')
                                _this.removeAttr('disabled')
                            }
                        }
                    })
                }
            }
            country.val('');

            return;
        } else {
        
            var data;
            if(country.val() === '') {
                data= {
                    needSave: true,
                    locationType: '1',
                    zipCode: zip.val().replace(', U.S.', '')
                }
            } else {
                data = {
                    needSave: true,
                    locationType: '3',
                    encodedCountryId: country.val()
                }
            }
    
            $.ajax({
                dataType: 'jsonp',
                jsonp: "jsoncallback",
                type: 'GET',
                url: '//www.crov.com/address/ships-to/save',
                data: data,
                cache: false,
                success: function(){
                    window.location.reload();
                    $headerShipDialog.removeClass('show');
                    $('.J-header-ship-mask').removeClass('show');
                },
                error: function() {
                    _this.removeAttr('disabled')
                }
            })
        }

    });
    



    $headerShipMWrap.on(tap, function() {
        if($(window).width() > 767) {
            $headerShipDialog.addClass('show');
            $('.J-header-ship-mask').addClass('show');
        } else {
            $headerShipDialogM.addClass('open');
            $('body').addClass('body-overflow');
            $('html').css({
                overflow: "hidden"
            });
        }
    });

    $headerShipDialogM.on(tap, '.J-goBack', function () {
        $headerShipDialogM.removeClass('open');
        bodyClose();
        clickClose($headerShipDialogM, originCountryM, originZipM)
    });

    // 校验邮编是不是正确的
    $('.J-header-ship-zip-confirm').on(tap, function() {
        if($(window).width() >= 768) {
            checkZip($headerShipDialog)
        } else {
            checkZip($headerShipDialogM)
        }
    });

    // zip输入框回车
    $headerShipDialog.on('keyup', 'input[name=headerShipZip]', function(e) {
        if(e.keyCode === 13) {
            checkZip($headerShipDialog)
        }
    });

    $headerShipDialogM.on('keyup', 'input[name=headerShipZip]', function(e) {
        if(e.keyCode === 13) {
            checkZip($headerShipDialogM)
        }
    });

    $headerShipDialog.on('change', 'select[name=country]', function() {
        changeCountry($headerShipDialog);
    });

    $headerShipDialogM.on('change', 'select[name=country]', function() {
        changeCountry($headerShipDialogM);
    });

    $('.J-zip-change').on(tap, function (e) {
        e.preventDefault();
        $('.J-zip-input-wrap').show();
        $('.J-zip-text-wrap').hide();
    });

    $('.J-close-header-ship').on(tap, function() {
        clickClose($headerShipDialog, originCountry, originZip)
    });
    // 侧边触屏下拉
    $('#j-select-btn').on(tap, function() {
        $('#j-select-content').toggle()
    })
    // 侧边触屏下拉
    $('#j-products-btn').on(tap, function() {
        $('#j-products-content').toggle()
    })
});

//广告日志发送
(function($) {
	//flash广告点击拦截
	$("body").bind("mousedown",function(e){
		var _this=$(e.target);
		if(e.target.tagName.toUpperCase()==="OBJECT" && e.which !== 3){
			commonTRClick(_this);
		}
	});

    var tap = 'ontouchend' in document ? 'touchend':'mousedown';

	$("[ads-data]").live(tap,function(e){
		if(e.which !== 3){
			commonTRClick($(this));
		}
	});

	//tm绑定
	var timeFlag=0;
	var timeBindTMClick=setInterval(function(){
		timeFlag+=2;
		if($("a.tm-on").size()>0){
			$("a.tm-on").bind("mousedown",function(e){
				if(e.which !== 3){
					tmTRClick($(this));
				}
			});
			clearInterval(timeBindTMClick);
		}
		if(timeFlag>20){
			clearInterval(timeBindTMClick);
		}
	},2000);
	function write2Cookie(obj) {
		var adsData = $.trim(obj.attr("ads-data"));
		if (adsData === "") {
			return;
		}
		adsData = adsData.replace(/aid/g, "pdid");
		var split_ads = adsData.split(",");
		var pdid_var="", i;
		for (i = 0; i < split_ads.length; i++) {
			if (split_ads[i] === "") {
				continue;
			} else {
				var split = split_ads[i].split(":");
				//去除related search兼容的pdid数据
				if(split[0] === "t" && (split[1]==="61" || split[1]==="61-1")){
					break;
				}
				if (split[0] === "pdid" && split[1] !== "") {
					pdid_var = split[1];
					break;
				}
			}
		}
		if (pdid_var === "") {
			return;
		}
		var ret = new Array();
		var cookie = $.cookie("_cwd");
		var cookieValue;
		if (cookie) {
			var cookie_str = $.cookie("_cwd").split("_");
			if($.inArray(pdid_var,cookie_str)==-1) {
				cookie_str.unshift(pdid_var);
			}else{
				return;
			}
			var max = cookie_str.length;
			if (max > 10) {
				max = 10;
			}
			for (i = 0; i < max; i++) {
				ret.push(cookie_str[i]);
			}
			cookieValue=ret.join("_");
		}else{
			cookieValue=pdid_var;
		}
		$.cookie("_cwd", cookieValue, {
			expires: 365, path: '/', domain: 'www.made-in-china.com', secure: false, raw: true
		});
	}



	//普通广告
	function commonTRClick(obj){
		var _this=obj;
		var adsData=$.trim(_this.attr("ads-data"));
		if(adsData===""){
			return false;
		}
		adsData=adsData.replace(/:/g,"=").replace(/,/g,"&");
		if(adsData.indexOf("t=15") < 0 && adsData.indexOf("t=51") < 0){
			var word=$(":hidden[name='ads_word']");
			if(adsData.indexOf("t=1") > -1){
				var correctedWord = $(":hidden[name='ads_corrected_word']");
				if(correctedWord.size()>0){
					adsData+="&sw=" + correctedWord.eq(0).val().replace(/&/g," ");
				} else {
					if(word.size()>0){
						adsData+="&sw=" + word.eq(0).val().replace(/&/g," ");
					}else{
						var code=$(":hidden[name='ads_code']");
						if(code.size()>0){
							adsData+="&sc=" + code.eq(0).val();
						}
					}
				}
			} else {
				if(word.size()>0){
					adsData+="&sw=" + word.eq(0).val().replace(/&/g," ");
				}else{
					var code=$(":hidden[name='ads_code']");
					if(code.size()>0){
						adsData+="&sc=" + code.eq(0).val();
					}
				}
			}
		}
		var img=new Image();
		img.onload=function(){
			img=null;
		};
		if(_this.attr("data-crovads")){
			img.src="https://stat.crov.com/event/common.html?oper=click&"+adsData+"&time="+new Date().valueOf();
		}else{
			img.src="https://stat.made-in-china.com/event/common.html?"+adsData+"&time="+new Date().valueOf();
		}
		write2Cookie(obj);
	}

	//广告tm点击
	function tmTRClick(obj){
		var _this=obj;
		var adsData=$.trim(_this.siblings("a[ads-data]").attr("ads-data"));
		if(adsData===""){
			return false;
		}
		var adsStr=adsData.replace(/:/g,"=").replace(/,/g,"&").replace(/&st=\d/g,"&st=7").replace(/&pa=\d/g,"&pa=7");
		var word=$(":hidden[name='ads_word']");
		if(word.size()>0){
			adsStr+="&sw=" + word.eq(0).val().replace(/&/g," ");
		}else{
			var code=$(":hidden[name='ads_code']");
			if(code.size()>0){
				adsStr+="&sc=" + code.eq(0).val();
			}
		}
		var img=new Image();
		img.onload=function(){
			img=null;
		};
		img.src="https://stat.made-in-china.com/event/common.html?"+adsStr+"&time="+new Date().valueOf();
	}
}(jQuery));




// 在历史B买家登录完成后，页面跳转至登录前的页面，识别出该用户在本次更新后登录的，弹出此弹窗，提醒该用户去完善账号信息
/* Pub Time: November 18, 2019 18:18:28, Version: 1.0.0 */(function(j,k){function p(a){var b=c.expando,d=a===j?0:a[b];d===k&&(a[b]=d=++c.uuid);return d}function r(){if(!c.isReady){try{document.documentElement.doScroll("left")}catch(a){setTimeout(r,1);return}c.ready()}}var c=j.art=function(a,b){return new c.fn.init(a,b)},m=!1,o=[],q,i="opacity"in document.documentElement.style,f=/^(?:[^<]*(<[\w\W]+>)[^>]*$|#([\w\-]+)$)/,g=/[\n\t]/g,n=/alpha\([^)]*\)/i,u=/opacity=([^)]*)/,s=/^([+-]=)?([\d+-.]+)(.*)$/;j.$===k&&(j.$=c);c.trim=function(a){return a.replace(/(^\s+|\s+$)/g,
    "")};c.fn=c.prototype={constructor:c,ready:function(a){c.bindReady();c.isReady?a.call(document,c):o&&o.push(a);return this},hasClass:function(a){return(" "+this[0].className+" ").replace(g," ").indexOf(" "+a+" ")>-1?true:false},addClass:function(a){if(!this.hasClass(a))this[0].className=c.trim(this[0].className)+" "+c.trim(a);return this},removeClass:function(a){var b=this[0];if(a){if(this.hasClass(a))b.className=b.className.replace(a," ")}else b.className="";return this},css:function(a,b){var d,
        h=this[0];if(typeof a==="string"){if(b===k)return c.css(h,a);a==="opacity"?c.opacity.set(h,b):h.style[a]=b}else for(d in a)d==="opacity"?c.opacity.set(h,a[d]):h.style[d]=a[d];return this},show:function(){return this.css("display","block")},hide:function(){return this.css("display","none")},offset:function(){var a=this[0],b=a.getBoundingClientRect(),d=a.ownerDocument,a=d.body,d=d.documentElement;return{left:b.left+(self.pageXOffset||d.scrollLeft)-(d.clientLeft||a.clientLeft||0),top:b.top+(self.pageYOffset||
        d.scrollTop)-(d.clientTop||a.clientTop||0)}},html:function(a){var b=this[0];if(a===k)return b.innerHTML;c.cleanData(b.getElementsByTagName("*"));b.innerHTML=a;return this},remove:function(){var a=this[0];c.cleanData(a.getElementsByTagName("*"));c.cleanData([a]);a.parentNode.removeChild(a);return this},bind:function(a,b){c.event.add(this[0],a,b);return this},unbind:function(a,b){c.event.remove(this[0],a,b);return this}};c.fn.init=function(a,b){var d,b=b||document;if(!a)return this;if(a.nodeType){this[0]=
        a;return this}if(a==="body"&&b.body){this[0]=b.body;return this}if(a==="head"||a==="html"){this[0]=b.getElementsByTagName(a)[0];return this}if(typeof a==="string")if((d=f.exec(a))&&d[2]){(d=b.getElementById(d[2]))&&d.parentNode&&(this[0]=d);return this}if(typeof a==="function")return c(document).ready(a);this[0]=a;return this};c.fn.init.prototype=c.fn;c.noop=function(){};c.isWindow=function(a){return a&&typeof a==="object"&&"setInterval"in a};c.isArray=function(a){return Object.prototype.toString.call(a)===
        "[object Array]"};c.fn.find=function(a){var b,d=this[0];if(b=a.split(".")[1])if(document.getElementsByClassName)b=d.getElementsByClassName(b);else{var h;h=d||document;var d=a=0,l=[];h=h.getElementsByTagName("*");var f=h.length;for(b=RegExp("(^|\\s)"+b+"(\\s|$)");a<f;a++)if(b.test(h[a].className)){l[d]=h[a];d++}b=l}else b=d.getElementsByTagName(a);return c(b[0])};c.each=function(a,b){var d,c=0,l=a.length;if(l===k)for(d in a){if(b.call(a[d],d,a[d])===false)break}else for(d=a[0];c<l&&b.call(d,c,d)!==
        false;d=a[++c]);return a};c.data=function(a,b,d){var h=c.cache,a=p(a);if(b===k)return h[a];h[a]||(h[a]={});d!==k&&(h[a][b]=d);return h[a][b]};c.removeData=function(a,b){var d=true,h=c.expando,l=c.cache,f=p(a),g=f&&l[f];if(g)if(b){delete g[b];for(var e in g)d=false;d&&delete c.cache[f]}else{delete l[f];a.removeAttribute?a.removeAttribute(h):a[h]=null}};c.uuid=0;c.cache={};c.expando="@cache"+ +new Date;c.event={add:function(a,b,d){var h,l=c.event;h=c.data(a,"@events")||c.data(a,"@events",{});h=h[b]=
        h[b]||{};(h.listeners=h.listeners||[]).push(d);if(!h.handler){h.elem=a;h.handler=l.handler(h);a.addEventListener?a.addEventListener(b,h.handler,false):a.attachEvent("on"+b,h.handler)}},remove:function(a,b,d){var h,l,f;l=c.event;var g=true,e=c.data(a,"@events");if(e)if(b){if(l=e[b]){f=l.listeners;if(d)for(h=0;h<f.length;h++)f[h]===d&&f.splice(h--,1);else l.listeners=[];if(l.listeners.length===0){a.removeEventListener?a.removeEventListener(b,l.handler,false):a.detachEvent("on"+b,l.handler);delete e[b];
        l=c.data(a,"@events");for(var w in l)g=false;g&&c.removeData(a,"@events")}}}else for(h in e)l.remove(a,h)},handler:function(a){return function(b){for(var b=c.event.fix(b||j.event),d=0,f=a.listeners,l;l=f[d++];)if(l.call(a.elem,b)===false){b.preventDefault();b.stopPropagation()}}},fix:function(a){if(a.target)return a;var b={target:a.srcElement||document,preventDefault:function(){a.returnValue=false},stopPropagation:function(){a.cancelBubble=true}},d;for(d in a)b[d]=a[d];return b}};c.cleanData=function(a){for(var b=
        0,d,f=a.length,l=c.event.remove,g=c.removeData;b<f;b++){d=a[b];l(d);g(d)}};c.isReady=!1;c.ready=function(){if(!c.isReady){if(!document.body)return setTimeout(c.ready,13);c.isReady=true;if(o){for(var a,b=0;a=o[b++];)a.call(document,c);o=null}}};c.bindReady=function(){if(!m){m=true;if(document.readyState==="complete")return c.ready();if(document.addEventListener){document.addEventListener("DOMContentLoaded",q,false);j.addEventListener("load",c.ready,false)}else if(document.attachEvent){document.attachEvent("onreadystatechange",
        q);j.attachEvent("onload",c.ready);var a=false;try{a=j.frameElement==null}catch(b){}document.documentElement.doScroll&&a&&r()}}};document.addEventListener?q=function(){document.removeEventListener("DOMContentLoaded",q,false);c.ready()}:document.attachEvent&&(q=function(){if(document.readyState==="complete"){document.detachEvent("onreadystatechange",q);c.ready()}});c.css="defaultView"in document&&"getComputedStyle"in document.defaultView?function(a,b){return document.defaultView.getComputedStyle(a,
        false)[b]}:function(a,b){return(b==="opacity"?c.opacity.get(a):a.currentStyle[b])||""};c.opacity={get:function(a){return i?document.defaultView.getComputedStyle(a,false).opacity:u.test((a.currentStyle?a.currentStyle.filter:a.style.filter)||"")?parseFloat(RegExp.$1)/100+"":1},set:function(a,b){if(i)return a.style.opacity=b;var d=a.style;d.zoom=1;var c="alpha(opacity="+b*100+")",f=d.filter||"";d.filter=n.test(f)?f.replace(n,c):d.filter+" "+c}};c.each(["Left","Top"],function(a,b){var d="scroll"+b;c.fn[d]=
        function(){var b=this[0],f;return(f=c.isWindow(b)?b:b.nodeType===9?b.defaultView||b.parentWindow:false)?"pageXOffset"in f?f[a?"pageYOffset":"pageXOffset"]:f.document.documentElement[d]||f.document.body[d]:b[d]}});c.each(["Height","Width"],function(a,b){var d=b.toLowerCase();c.fn[d]=function(a){var d=this[0];return!d?a==null?null:this:c.isWindow(d)?d.document.documentElement["client"+b]||d.document.body["client"+b]:d.nodeType===9?Math.max(d.documentElement["client"+b],d.body["scroll"+b],d.documentElement["scroll"+
        b],d.body["offset"+b],d.documentElement["offset"+b]):null}});c.ajax=function(a){var b=j.XMLHttpRequest?new XMLHttpRequest:new ActiveXObject("Microsoft.XMLHTTP"),d=a.url;if(a.cache===false)var f=+new Date,g=d.replace(/([?&])_=[^&]*/,"$1_="+f),d=g+(g===d?(/\?/.test(d)?"&":"?")+"_="+f:"");b.onreadystatechange=function(){if(b.readyState===4&&b.status===200){a.success&&a.success(b.responseText);b.onreadystatechange=c.noop}};b.open("GET",d,1);b.send(null)};c.fn.animate=function(a,b,d,f){typeof d==="function"&&
        (f=d);var d=d&&c.easing[d]?d:"swing",g=this[0],i,j,e,w,t,y,z={speed:b||400,easing:d,callback:function(){if(i!=null)g.style.overflow="";f&&f()},curAnim:{}};c.each(a,function(e,a){z.curAnim[e]=a});c.each(a,function(a,d){j=new c.fx(g,z,a);e=s.exec(d);w=parseFloat(a==="opacity"||g.style&&g.style[a]!=null?c.css(g,a):g[a]);t=parseFloat(e[2]);y=e[3];if(a==="height"||a==="width"){t=Math.max(0,t);i=[g.style.overflow,g.style.overflowX,g.style.overflowY]}j.custom(w,t,y)});if(i!=null)g.style.overflow="hidden";
        return this};c.timers=[];c.fx=function(a,b,d){this.elem=a;this.options=b;this.prop=d};c.fx.prototype={custom:function(a,b,d){function f(){return g.step()}var g=this;g.startTime=c.fx.now();g.start=a;g.end=b;g.unit=d;g.now=g.start;g.state=g.pos=0;f.elem=g.elem;f();c.timers.push(f);if(!c.timerId)c.timerId=setInterval(c.fx.tick,13)},step:function(){var a=c.fx.now(),b=true;if(a>=this.options.speed+this.startTime){this.now=this.end;this.state=this.pos=1;this.update();this.options.curAnim[this.prop]=true;
        for(var d in this.options.curAnim)this.options.curAnim[d]!==true&&(b=false);b&&this.options.callback.call(this.elem);return false}a=a-this.startTime;this.state=a/this.options.speed;this.pos=c.easing[this.options.easing](this.state,a,0,1,this.options.speed);this.now=this.start+(this.end-this.start)*this.pos;this.update();return true},update:function(){this.prop==="opacity"?c.opacity.set(this.elem,this.now):this.elem.style&&this.elem.style[this.prop]!=null?this.elem.style[this.prop]=this.now+this.unit:
        this.elem[this.prop]=this.now}};c.fx.now=function(){return+new Date};c.easing={linear:function(a,b,d,c){return d+c*a},swing:function(a,b,d,c){return(-Math.cos(a*Math.PI)/2+0.5)*c+d}};c.fx.tick=function(){for(var a=c.timers,b=0;b<a.length;b++)!a[b]()&&a.splice(b--,1);!a.length&&c.fx.stop()};c.fx.stop=function(){clearInterval(c.timerId);c.timerId=null};c.fn.stop=function(){for(var a=c.timers,b=a.length-1;b>=0;b--)a[b].elem===this[0]&&a.splice(b,1);return this};return c})(window);
        (function(j,k,p){j.noop=j.noop||function(){};var r,c,m,o,q=0,i=j(k),f=j(document),g=j("html");m=document.documentElement;var n=k.VBArray&&!k.XMLHttpRequest,u="createTouch"in document&&!("onmousemove"in m)||/(iPhone|iPad|iPod)/i.test(navigator.userAgent),s="artDialog"+ +new Date,a={};j(document).ready(function(){for(var e=document.getElementsByTagName("link"),d,b,c=0;c<e.length;c++)(d=e[c].href)&&(b=d.replace(/\?.*$/,"")),a[b]=!0});var b=function(e){if(e&&(e=/\.s?css(?:\?|$)/.test(e)?e:o+"/skins/"+
        e+".css",e=e.replace(/\?.*$/,""),!(e in a))){var e=e+"?"+d.fn.version,b=document.createElement("link");b.rel="stylesheet";b.href=e;c.parentNode.insertBefore(b,c)}},d=function(e,a,b){e=e||{};if("string"===typeof e||1===e.nodeType)e={content:e,fixed:!u};var c;c=d.defaults;var f=e.follow=1===this.nodeType&&this||e.follow,g;for(g in c)e[g]===p&&(e[g]=c[g]);"string"===typeof f&&(f=j(f)[0]);e.id=f&&f[s+"follow"]||e.id||s+q;c=d.list[e.id];if(f&&c)return c.follow(f).zIndex().focus();if(c)return c.zIndex().focus();
        u&&(e.fixed=!1);j.isArray(e.button)||(e.button=e.button?[e.button]:[]);a!==p&&(e.ok=a);b!==p&&(e.cancel=b);e.ok&&e.button.push({name:e.okVal,callback:e.ok,focus:!0});e.cancel&&e.button.push({name:e.cancelVal,callback:e.cancel});d.defaults.zIndex=e.zIndex;q++;return d.list[e.id]=r?r._init(e):new d.fn._init(e)},h=m=d,l=n?function(){for(var e=0,a,b,c=d.defaults.path+"/skins/",f=this.DOM.wrap[0].getElementsByTagName("*");e<f.length;e++)if(a=f[e],b=a.currentStyle.png)b=c+b,a=a.runtimeStyle,a.backgroundImage=
        "none",a.filter="progid:DXImageTransform.Microsoft.AlphaImageLoader(src='"+b+"',sizingMethod='crop')"}:j.noop,v=n?function(){var e=this.DOM.wrap,a=e[0],b=s+"iframeMask",d=e[b],c=a.offsetWidth,f=a.offsetHeight,c=c+"px",f=f+"px";d?(d.style.width=c,d.style.height=f):(d=a.appendChild(document.createElement("iframe")),e[b]=d,d.src="about:blank",d.style.cssText="position:absolute;z-index:-1;left:0;top:0;filter:alpha(opacity=0);width:"+c+";height:"+f)}:j.noop;n&&j(function(){"fixed"!==g.css("backgroundAttachment")&&
        "fixed"!==j("body").css("backgroundAttachment")&&g.css({zoom:1,backgroundImage:"url(about:blank)",backgroundAttachment:"fixed"})});m.fn=h.prototype={version:"4.1.7a",closed:!0,_init:function(e){var a,d=e.icon,c=d&&(n?{png:"icons/"+d+".png"}:{backgroundImage:"url('"+e.path+"/skins/icons/"+d+".png')"});b(e.skin);this.closed=!1;this.config=e;this.DOM=a=this.DOM||this._getDOM();a.wrap.addClass(e.skin);a.wrap[0].style.zoom=1;a.close[!1===e.cancel?"hide":"show"]();a.icon[0].style.display=d?"":"none";a.iconBg.css(c||
        {background:"none"});a.se.css("cursor",e.resize?"se-resize":"auto");a.title.css("cursor",e.drag?"move":"auto");a.content.css("padding",e.padding);this[e.show?"show":"hide"](!0);this.footer(e.footer);this.button(e.button).title(e.title).content(e.content,!0,{type:e.type}).size(e.width,e.height).time(e.time);e.follow?this.follow(e.follow):this.position(e.left,e.top);e._expando=s;this.defaultFocus();this.zIndex().focus();e.lock&&this.lock();this._addEvent();this._ie6PngFix();r=null;e.init&&e.init.call(this,
        k);return this},content:function(e,a){var d,b,c,f,g=this,i=g.DOM,h=i.wrap[0],j=h.offsetWidth,n=h.offsetHeight,l=parseInt(h.style.left),k=parseInt(h.style.top),q=h.style.width,i=i.content,m=i[0];g._elemBack&&g._elemBack();h.style.width="auto";if(e===p)return m;"string"===typeof e?i.html(e):e&&1===e.nodeType&&(f=e.style.display,d=e.previousSibling,b=e.nextSibling,c=e.parentNode,g._elemBack=function(){d&&d.parentNode?d.parentNode.insertBefore(e,d.nextSibling):b&&b.parentNode?b.parentNode.insertBefore(e,
        b):c&&c.appendChild(e);e.style.display=f;g._elemBack=null},i.html(""),m.appendChild(e),e.style.display="block");a||(g.config.follow?g.follow(g.config.follow):(j=h.offsetWidth-j,n=h.offsetHeight-n,k-=n/2,h.style.left=Math.max(l-j/2,0)+"px",h.style.top=Math.max(k,0)+"px"),q&&"auto"!==q&&(h.style.width=h.offsetWidth+"px"),g._autoPositionType());g._ie6SelectFix();g._runScript(m);return g},title:function(e){var a=this.DOM,d=a.wrap,a=a.title;if(e===p)return a[0];!1===e?(a.hide().html(""),d.addClass("dlg-state-notitle")):
        (a.show().html(e||""),d.removeClass("dlg-state-notitle"));return this},footer:function(e){var a=this.DOM;if(e===p)return a.footerContent[0];e=e||"";"string"===typeof e?a.footerContent.html(e):(a.footerContent.html(""),a.footerContent[0].appendChild(e));"string"===typeof e&&/^\s*$/.test(e)||!e?a.footerContent.hide():a.footerContent.show();this._checkFooterStatus();this._ie6SelectFix();return this},position:function(e,a){var d=this.config,b=this.DOM.wrap[0],c=n?!1:d.fixed,d=n&&this.config.fixed,g=f.scrollLeft(),
        h=f.scrollTop(),j=c?0:g,c=c?0:h,l=i.width(),k=i.height(),q=b.offsetWidth,m=b.offsetHeight,b=b.style;if(e||0===e)this._left=-1!==e.toString().indexOf("%")?e:null,e=this._toNumber(e,l-q),"number"===typeof e?(e=d?e+=g:e+j,b.left=Math.max(e,j)+"px"):"string"===typeof e&&(b.left=e);if(a||0===a)this._top=-1!==a.toString().indexOf("%")?a:null,a=this._toNumber(a,k-m),"number"===typeof a?(a=d?a+=h:a+c,b.top=Math.max(a,c)+"px"):"string"===typeof a&&(b.top=a);e!==p&&a!==p&&(this._follow=null,this._autoPositionType());
        return this},size:function(e,a){var d,b,c=this.DOM;b=c.wrap;var f=c.main,g=b[0].style,c=f[0].style;e&&(this._width=-1!==e.toString().indexOf("%")?e:null,d=i.width()-b[0].offsetWidth+f[0].offsetWidth,e=d=this._toNumber(e,d),"number"===typeof e?(g.width="auto",c.width=Math.max(this.config.minWidth,e)+"px",g.width=b[0].offsetWidth+"px"):"string"===typeof e&&(c.width=e,"auto"===e&&b.css("width","auto")));a&&(this._height=-1!==a.toString().indexOf("%")?a:null,b=i.height()-b[0].offsetHeight+f[0].offsetHeight,
        a=b=this._toNumber(a,b),"number"===typeof a?c.height=Math.max(this.config.minHeight,a)+"px":"string"===typeof a&&(c.height=a));this._ie6SelectFix();return this},follow:function(e){var a,b=this.config;if("string"===typeof e||e&&1===e.nodeType)a=j(e),e=a[0];if(!e||!e.offsetWidth&&!e.offsetHeight)return this.position(this._left,this._top);var d=s+"follow",c=i.width(),g=i.height(),h=f.scrollLeft(),l=f.scrollTop(),k=a.offset();a=e.offsetWidth;var q=n?!1:b.fixed,m=q?k.left-h:k.left,k=q?k.top-l:k.top,o=
        this.DOM.wrap[0],p=o.style,u=o.offsetWidth,o=o.offsetHeight,r=m-(u-a)/2,v=k+e.offsetHeight,h=q?0:h,l=q?0:l;p.left=(r<h?m:r+u>c&&m-u>h?m-u+a:r)+"px";p.top=(v+o>g+l&&k-o>l?k-o:v)+"px";this._follow&&this._follow.removeAttribute(d);this._follow=e;e[d]=b.id;this._autoPositionType();return this},button:function(){var e=this,a=arguments,b=e.DOM.buttons,d=b[0],c=e._listeners=e._listeners||{},f=j.isArray(a[0])?a[0]:[].slice.call(a);if(a[0]===p)return d;j.each(f,function(a,b){var f=b.name,g=!c[f],h=!g?c[f].elem:
        document.createElement("button");b.link&&(h=document.createElement("a"),h.href=b.link,b.target&&(h.target=b.target));c[f]||(c[f]={});b.callback&&(c[f].callback=b.callback);b.className&&(h.className=b.className);j(h).addClass("btn");b.focus&&(e._focus&&e._focus.removeClass("btn-main"),e._focus=j(h).addClass("btn-main"),e.focus());b.highlight&&j(h).addClass("btn-main");h.setAttribute("type","button");h[s+"callback"]=f;h.disabled=!!b.disabled;b.disabled?j(h).addClass("btn-disabled"):j(h).removeClass("btn-disabled");
        g&&(h.innerHTML=f,c[f].elem=h,d.appendChild(h))});b[0].style.display=f.length?"":"none";f.length||b.html("");e._checkFooterStatus();e._ie6SelectFix();return e},defaultFocus:function(){this._defaultFocus=j("#dlg-default-focus-elem");this._defaultFocus.bind("click",function(e){e=e||k.event;e.preventDefault?e.preventDefault():e.returnValue=!1});return this},show:function(e){this.DOM.wrap.show();!e&&this._lockMaskWrap&&this._lockMaskWrap.show();return this},hide:function(e){this.DOM.wrap.hide();!e&&this._lockMaskWrap&&
        this._lockMaskWrap.hide();return this},_checkFooterStatus:function(){var e=j.trim(this.DOM.footerContent.html()),a=j.trim(this.DOM.buttons.html());!e&&!a?this.DOM.footer.hide():this.DOM.footer.show()},close:function(){if(this.closed)return this;var e=this.DOM,a=e.wrap,b=d.list,c=this.config.close,f=this.config.follow;if(e.content.find("iframe")[0]&&!/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){var g=k.scrollTop||document.documentElement.scrollTop||document.body.scrollTop||
        0,h=document.createElement("input");h.type="text";document.body.appendChild(h);h.style.cssText="position:absolute; top:"+(g+100)+"px; left:-500px;";h.focus();setTimeout(function(){h.blur();document.body.removeChild(h)},0)}this.time();if("function"===typeof c&&!1===c.call(this,k))return this;this.unlock();this._elemBack&&this._elemBack();a[0].className=a[0].style.cssText="";e.title.html("");e.content.html("");e.buttons.html("");e.footerContent.html("");d.focus===this&&(d.focus=null);f&&f.removeAttribute(s+
        "follow");delete b[this.config.id];this._removeEvent();this.hide(!0)._setAbsolute();for(var i in this)this.hasOwnProperty(i)&&"DOM"!==i&&delete this[i];r?a.remove():r=this;return this},time:function(e,a){var b=this,d=b.config.cancelVal,c=b._timer;c&&clearTimeout(c);e&&(b._timer=setTimeout(function(){b._click(d);"function"===typeof a&&a.call(b)},1E3*e));return b},focus:function(){try{if(this.config.focus){var a=this._focus&&this._focus[0]||this._defaultFocus&&this._defaultFocus[0];a&&a.focus()}}catch(b){}return this},
        zIndex:function(){var a=this.DOM.wrap,b=d.focus,c=d.defaults.zIndex++;a.css("zIndex",c);this._lockMask&&this._lockMask.css("zIndex",c-1);b&&b.DOM.wrap.removeClass("dlg-state-focus");d.focus=this;a.addClass("dlg-state-focus");return this},lock:function(){if(this._lock)return this;var a=this,b=d.defaults.zIndex-1,c=a.DOM.wrap,g=a.config;f.width();f.height();var h=a._lockMaskWrap||j(document.body.appendChild(document.createElement("div"))),i=a._lockMask||j(h[0].appendChild(document.createElement("div"))),
        k=n?"position:absolute;left:expression((document).documentElement.scrollLeft);top:expression((document).documentElement.scrollTop);width:expression((document).documentElement.clientWidth);height:expression((document).documentElement.clientHeight)":"";a.zIndex();c.addClass("dlg-state-lock");h[0].style.cssText="width:100%;height:100%;position:fixed;z-index:"+b+";top:0;left:0;overflow:hidden;"+k;i[0].style.cssText="height:100%;background:"+g.background+";filter:alpha(opacity=0);opacity:0";n&&i.html('<iframe src="about:blank" style="width:100%;height:100%;position:absolute;top:0;left:0;z-index:-1;filter:alpha(opacity=0)"></iframe>');
        i.stop();i.bind("click",function(){a._reset()});0===g.duration?i.css({opacity:g.opacity}):i.animate({opacity:g.opacity},g.duration);a._lockMaskWrap=h;a._lockMask=i;a._lock=!0;return a},unlock:function(){var a=this._lockMaskWrap,b=this._lockMask;if(!this._lock)return this;var c=a[0].style,d=function(){n&&(c.removeExpression("width"),c.removeExpression("height"),c.removeExpression("left"),c.removeExpression("top"));c.cssText="display:none";r&&a.remove()};b.stop().unbind();this.DOM.wrap.removeClass("dlg-state-lock");
        this.config.duration?b.animate({opacity:0},this.config.duration,d):d();this._lock=!1;return this},_getDOM:function(){var a=document.createElement("div"),b=document.body;a.style.cssText="position:absolute;left:0;top:0";a.innerHTML=d._templates;b.insertBefore(a,b.firstChild);for(var b=0,c={wrap:j(a)},f=a.getElementsByTagName("*"),g=f.length;b<g;b++)if(a=f[b].className.split("dlg-")[1])a=a.replace(/\-(\w)/g,function(a,e){return e.toUpperCase()}),c[a]=j(f[b]);return c},_toNumber:function(a,b){if(!a&&
        0!==a||"number"===typeof a)return a;var c=a.length-1;a.lastIndexOf("px")===c?a=parseInt(a):a.lastIndexOf("%")===c&&(a=parseInt(b*a.split("%")[0]/100));return a},_ie6PngFix:l,_ie6SelectFix:v,_runScript:function(a){for(var b,c=b=0,a=a.getElementsByTagName("script"),d=a.length,f=[];b<d;b++)"text/dialog"===a[b].type&&(f[c]=a[b].innerHTML,c++);f.length&&(f=f.join(""),b=new Function(f),b.call(this))},_autoPositionType:function(){this[this.config.fixed?"_setFixed":"_setAbsolute"]()},_setFixed:function(){var a=
        this.DOM.wrap,b=a[0].style;if(n){var c=parseInt(a.css("left")),a=parseInt(a.css("top")),d=f.scrollLeft(),g=f.scrollTop();this._setAbsolute();b.setExpression("left","eval((document.documentElement).scrollLeft + "+(c-d)+') + "px"');b.setExpression("top","eval((document.documentElement).scrollTop + "+(a-g)+') + "px"')}else b.position="fixed"},_setAbsolute:function(){var a=this.DOM.wrap[0].style;n&&(a.removeExpression("left"),a.removeExpression("top"));a.position="absolute"},_click:function(a){a=this._listeners[a]&&
        this._listeners[a].callback;return"function"!==typeof a||!1!==a.call(this,k)?this.close():this},clickButton:function(a){this._click(a)},_reset:function(a){var b=this._winSize||i.width()*i.height(),c=this._follow,d=this._width,f=this._height,g=this._left,h=this._top;if(a&&(a=this._winSize=i.width()*i.height(),b===a))return;(d||f)&&this.size(d,f);c?this.follow(c):(g||h)&&this.position(g,h)},reset:function(){var a=this;setTimeout(function(){a._reset()},0)},_addEvent:function(){var a,b=this,c=b.config,
        d="CollectGarbage"in k,f=b.DOM;b._winResize=function(){a&&clearTimeout(a);a=setTimeout(function(){b._reset(d)},40)};i.bind("resize",b._winResize);f.wrap.bind("click",function(a){a=a.target;if(a.disabled)return!1;if(a===f.close[0]||a===f.close.find(".micon")[0])return b._click(c.cancelVal),!1;(a=a[s+"callback"])&&b._click(a);b._ie6SelectFix()}).bind("mousedown",function(){b.zIndex()})},_removeEvent:function(){this.DOM.wrap.unbind();i.unbind("resize",this._winResize)}};d.fn._init.prototype=d.fn;j.fn.dialog=
        j.fn.artDialog=function(){var a=arguments;this[this.live?"live":"bind"]("click",function(){d.apply(this,a);return!1});return this};d.focus=null;d.get=function(a){return a===p?d.list:d.list[a]};d.list={};f.bind("keydown",function(a){var b=a.target.nodeName,c=/^INPUT|TEXTAREA$/,f=d.focus,a=a.keyCode;f&&f.config.esc&&!c.test(b)&&27===a&&f._click(f.config.cancelVal)});if(!(m=k._artDialog_path)){m=document.getElementsByTagName("script");for(l=h=void 0;h<m.length;h++)m[h].src&&-1!==m[h].src.indexOf("artDialog")&&
        (l=m[h]);c=l||m[m.length-1];l=c.src.replace(/\\/g,"/");m=0>l.lastIndexOf("/")?".":l.substring(0,l.lastIndexOf("/"))}o=m;(m=c.src.match(/[\?&]skin=([^&#]*)/))&&b(m[1]);i.bind("load",function(){setTimeout(function(){q||d({time:9,fixed:!1,lock:!1,focus:!1}).position("-9999em","-9999em")},150)});try{document.execCommand("BackgroundImageCache",!1,!0)}catch(x){}d._templates='<div class="dlg-dialog"><div class="dlg-header"><div class="dlg-titlebar"><div class="dlg-title"></div><span class="dlg-close">×</span></div></div><div class="dlg-icon"><div class="dlg-iconBg"></div></div><div class="dlg-main"><div class="dlg-content"></div></div><div class="dlg-footer"><div class="dlg-footer-content"></div><div class="dlg-buttons"></div></div></div><div class="dlg-se"></div><a href="#" id="dlg-default-focus-elem" style="margin-top:-20px; display:block; width:1px; height:1px; overflow:hidden;">x</a>';
        d.defaults={content:'<div class="dlg-loading"><span>loading..</span></div>',title:"消息",footer:"",button:null,ok:null,cancel:null,init:null,close:null,okVal:"确定",cancelVal:"取消",width:"auto",height:"auto",minWidth:0,minHeight:0,padding:"",skin:"",icon:null,time:null,esc:!0,focus:!0,show:!0,follow:null,path:o,lock:!1,background:"#000",opacity:0.4,duration:300,fixed:!1,left:"50%",top:"38.2%",zIndex:1987,resize:!0,drag:!0};k.artDialog=j.dialog=j.artDialog=d})(this.art||this.jQuery&&(this.art=jQuery),this);
        (function(j){var k,p,r=j(window),c=j(document),m=document.documentElement,o=!("minWidth"in m.style),q="onlosecapture"in m,i="setCapture"in m;artDialog.dragEvent=function(){var c=this,g=function(g){var i=c[g];c[g]=function(){return i.apply(c,arguments)}};g("start");g("move");g("end")};artDialog.dragEvent.prototype={onstart:j.noop,start:function(f){c.bind("mousemove",this.move).bind("mouseup",this.end);this._sClientX=f.clientX;this._sClientY=f.clientY;this.onstart(f.clientX,f.clientY);return!1},onmove:j.noop,
        move:function(c){this._mClientX=c.clientX;this._mClientY=c.clientY;this.onmove(c.clientX-this._sClientX,c.clientY-this._sClientY);return!1},onend:j.noop,end:function(f){c.unbind("mousemove",this.move).unbind("mouseup",this.end);this.onend(f.clientX,f.clientY);return!1}};p=function(f){var g,j,m,p,a,b,d=artDialog.focus,h=d.DOM,l=h.wrap,v=h.title,x=h.main,e="getSelection"in window?function(){window.getSelection().removeAllRanges()}:function(){try{document.selection.empty()}catch(a){}};k.onstart=function(){b?
        (j=x[0].offsetWidth,m=x[0].offsetHeight):(p=l[0].offsetLeft,a=l[0].offsetTop);c.bind("dblclick",k.end);!o&&q?v.bind("losecapture",k.end):r.bind("blur",k.end);i&&v[0].setCapture();l.addClass("dlg-state-drag");d.focus()};k.onmove=function(c,f){if(b){var h=l[0].style,i=x[0].style,k=c+j,q=f+m;h.width="auto";i.width=Math.max(0,k)+"px";h.width=l[0].offsetWidth+"px";i.height=Math.max(0,q)+"px"}else i=l[0].style,h=Math.max(g.minX,Math.min(g.maxX,c+p)),k=Math.max(g.minY,Math.min(g.maxY,f+a)),i.left=h+"px",
        i.top=k+"px";e();d._ie6SelectFix()};k.onend=function(){c.unbind("dblclick",k.end);!o&&q?v.unbind("losecapture",k.end):r.unbind("blur",k.end);i&&v[0].releaseCapture();o&&!d.closed&&d._autoPositionType();l.removeClass("dlg-state-drag")};b=f.target===h.se[0]?!0:!1;var w=d.DOM.wrap[0],t="fixed"===w.style.position,h=w.offsetWidth,w=w.offsetHeight,y=r.width(),z=r.height(),A=t?0:c.scrollLeft(),t=t?0:c.scrollTop();g={minX:A,minY:t,maxX:y-h+A,maxY:z-w+t};k.start(f)};c.bind("mousedown",function(c){var g=artDialog.focus;
        if(g){var i=c.target,j=g.config,g=g.DOM;if(!1!==j.drag&&i===g.title[0]||!1!==j.resize&&i===g.se[0])return k=k||new artDialog.dragEvent,p(c),!1}})})(this.art||this.jQuery&&(this.art=jQuery));
        void function(){var j=Object.prototype.toString,k=function(c){return"undefined"===typeof c?"undefined":j.call(c).match(/\w+/g)[1].toLowerCase()},p=function(c,i){var f={},g;for(g in c)f[g]=c[g];for(g in i)f[g]=i[g];return f},r={defaults:"",tip:"",error:"confirm-wrong",success:"confirm-succ"},c=function(c,i){if("string"===typeof c)var f={content:i,type:r[c]||r.defaults},i='<div class="dialog-confirm {=type}"><i class="ico"></i><div class="cnt">{=content}</div></div>'.replace(/{\=(\w+)}/g,function(c,
        i){return f[i]});return i},m=artDialog.prototype.content;artDialog.prototype.content=function(j,i,f){var g=arguments,f=g[g.length-1],n;1<g.length&&f&&(n=f.type);"string"===typeof j?j=c(n,j):j&&j.nodeType&&(g=c(n,"<b></b>"),"<b></b>"!==g&&(n=g,g=document.createElement("div"),g.style.cssText="position:absolute; top:0; left:0; width:1px; height:1px; overflow:hidden;",g.innerHTML=n,document.body.appendChild(g),n=g.children[0],document.body.removeChild(g),g.innerHTML="",g=n,n=g.getElementsByTagName("b")[0].parentNode,
        n.innerHTML="",n.appendChild(j),j=g));i="object"===k(i)?!1:!!i;return m.call(this,j,i)};var o=function(){};artDialog.confirm=function(c,i,f,g,j){"string"!==typeof i&&(j=g,g=f,f=i,i=!1);var m=k(f),r=k(g);"object"===m&&!("text"in f&&"fn"in f)?(j=f,f=g=o):"function"===m&&("object"===r&&!("text"in g&&"fn"in g))&&(j=g,g=o);var f=f||o,g=g||o,j=j||{},a="function"===typeof f?{fn:f,text:"确认"}:f,b="function"===typeof g?{fn:g,text:"取消"}:g,d=new artDialog(p({title:i||!1,content:c,resize:!1,lock:!0,fixed:!0,cancelVal:b.text,
        button:[{name:a.text||"确认",callback:function(){return a.fn&&a.fn.call(d)}||o,link:a.link,target:a.target,highlight:!0},{name:b.text||"取消",link:b.link,target:b.target,callback:function(){return b.fn&&b.fn.call(d)}||o}]},j));return d};artDialog.alert=function(c,i,f,g){var j=k(f),m=k(i);"function"===m&&(g=f,f=i,i="");"object"===m&&("text"in i&&"fn"in i?(g=f,f=i,i=""):(g=i,i=f=""));"string"===m&&("object"===j&&!("text"in f&&"fn"in f))&&(g=f,f="");i=i||"";f=f||o;g=g||{};"function"===k(f)&&(f={text:"",
        fn:f});return new artDialog(p({title:i||!1,content:c,resize:!1,lock:!0,fixed:!0,button:[{name:f.text||"确定",callback:f.fn,highlight:!0}]},g))};artDialog.tip=function(c,i,f){"object"===k(i)&&(f=i,i="");f=f||{};return new artDialog(p({title:i||""||!1,content:c,resize:!1,lock:!0,fixed:!0,padding:"30px 20px"},f))}}.call(this);
        
// Copyright 2017 FOCUS Inc.All Rights Reserved.

/**
 * @fileOverview dev_4
 * @author sheny@made-in-china.com
 */
(function() {
    // 2017-06-06 lock属性增加隐藏页面滚动条功能

    var _lock = artDialog.prototype.lock,
        _unlock = artDialog.prototype.unlock;

    $.extend(artDialog.prototype, {
        /** 设置屏锁 */
        lock: function () {
            if (this._lock) return this;

            // hidden body scroll
            $('body').addClass('scroll-hidden');

            _lock.call(this);

            this.maskWrap = this._lockMaskWrap;

            return this;
        },

        /** 解开屏锁 */
        unlock: function () {
            if (!this._lock) return this;

            // show body scroll
            $('body').removeClass('scroll-hidden');

            this.maskWrap = null;

            return _unlock.call(this);
        }
    });
}());
$(function () {
    //历史B买家首次登录
    var COOKIENAME = 'oldBusinessFirst';

    // 显示弹框
    function showNoticeDialog() {
        if(typeof artDialog === 'undefined') return;
        artDialog.confirm("Please take a few minutes to complete your business information, so we could provide you with better service.", "Update your profile" , {
            text: "Complete Now",
            fn: function(){
                window.location.href = '//buyer.crov.com/account/edit';
            }
        }, {
            text: "Do it later",
            fn: function(){

            }
        }, {
            type: "tip",
            close: function () {
                // 删除cookie
                $.removeCookie(COOKIENAME, {domain: 'crov.com'});
            }
        });
    }
    // 判断是否需要显示弹框
    if($.cookie(COOKIENAME) === '1'){
        showNoticeDialog()
    }

});
/**
 * jQuery.marquee - scrolling text like old marquee element
 * @author Aamir Afridi - aamirafridi(at)gmail(dot)com / http://aamirafridi.com/jquery/jquery-marquee-plugin
 */
(function($){$.fn.marquee=function(options){return this.each(function(){var o=$.extend({},$.fn.marquee.defaults,options),$this=$(this),$marqueeWrapper,containerWidth,animationCss,verticalDir,elWidth,loopCount=3,playState='animation-play-state',css3AnimationIsSupported=!1,_prefixedEvent=function(element,type,callback){var pfx=["webkit","moz","MS","o",""];for(var p=0;p<pfx.length;p++){if(!pfx[p])type=type.toLowerCase();element.addEventListener(pfx[p]+type,callback,!1)}},_objToString=function(obj){var tabjson=[];for(var p in obj){if(obj.hasOwnProperty(p)){tabjson.push(p+':'+obj[p])}}
tabjson.push();return'{'+tabjson.join(',')+'}'},_startAnimationWithDelay=function(){$this.timer=setTimeout(animate,o.delayBeforeStart)},methods={pause:function(){if(css3AnimationIsSupported&&o.allowCss3Support){$marqueeWrapper.css(playState,'paused')}else{if($.fn.pause){$marqueeWrapper.pause()}}
$this.data('runningStatus','paused');$this.trigger('paused')},resume:function(){if(css3AnimationIsSupported&&o.allowCss3Support){$marqueeWrapper.css(playState,'running')}else{if($.fn.resume){$marqueeWrapper.resume()}}
$this.data('runningStatus','resumed');$this.trigger('resumed')},toggle:function(){methods[$this.data('runningStatus')=='resumed'?'pause':'resume']()},destroy:function(){clearTimeout($this.timer);$this.find("*").addBack().off();$this.html($this.find('.js-marquee:first').html())}};if(typeof options==='string'){if($.isFunction(methods[options])){if(!$marqueeWrapper){$marqueeWrapper=$this.find('.js-marquee-wrapper')}
if($this.data('css3AnimationIsSupported')===!0){css3AnimationIsSupported=!0}
methods[options]()}
return}
var dataAttributes={},attr;$.each(o,function(key,value){attr=$this.attr('data-'+key);if(typeof attr!=='undefined'){switch(attr){case 'true':attr=!0;break;case 'false':attr=!1;break}
o[key]=attr}});if(o.speed){o.duration=parseInt($this.width(),10)/o.speed*1000}
verticalDir=o.direction=='up'||o.direction=='down';o.gap=o.duplicated?parseInt(o.gap):0;$this.wrapInner('<div class="js-marquee"></div>');var $el=$this.find('.js-marquee').css({'margin-right':o.gap,'float':'left'});if(o.duplicated){$el.clone(!0).appendTo($this)}
$this.wrapInner('<div style="width:100000px" class="js-marquee-wrapper"></div>');$marqueeWrapper=$this.find('.js-marquee-wrapper');if(verticalDir){var containerHeight=$this.height();$marqueeWrapper.removeAttr('style');$this.height(containerHeight);$this.find('.js-marquee').css({'float':'none','margin-bottom':o.gap,'margin-right':0});if(o.duplicated)$this.find('.js-marquee:last').css({'margin-bottom':0});var elHeight=$this.find('.js-marquee:first').height()+o.gap;if(o.startVisible&&!o.duplicated){o._completeDuration=((parseInt(elHeight,10)+parseInt(containerHeight,10))/parseInt(containerHeight,10))*o.duration;o.duration=(parseInt(elHeight,10)/parseInt(containerHeight,10))*o.duration}else{o.duration=((parseInt(elHeight,10)+parseInt(containerHeight,10))/parseInt(containerHeight,10))*o.duration}}else{elWidth=$this.find('.js-marquee:first').width()+o.gap;containerWidth=$this.width();if(o.startVisible&&!o.duplicated){o._completeDuration=((parseInt(elWidth,10)+parseInt(containerWidth,10))/parseInt(containerWidth,10))*o.duration;o.duration=(parseInt(elWidth,10)/parseInt(containerWidth,10))*o.duration}else{o.duration=((parseInt(elWidth,10)+parseInt(containerWidth,10))/parseInt(containerWidth,10))*o.duration}}
if(o.duplicated){o.duration=o.duration/2}
if(o.allowCss3Support){var
elm=document.body||document.createElement('div'),animationName='marqueeAnimation-'+Math.floor(Math.random()*10000000),domPrefixes='Webkit Moz O ms Khtml'.split(' '),animationString='animation',animationCss3Str='',keyframeString='';if(elm.style.animation!==undefined){keyframeString='@keyframes '+animationName+' ';css3AnimationIsSupported=!0}
if(css3AnimationIsSupported===!1){for(var i=0;i<domPrefixes.length;i++){if(elm.style[domPrefixes[i]+'AnimationName']!==undefined){var prefix='-'+domPrefixes[i].toLowerCase()+'-';animationString=prefix+animationString;playState=prefix+playState;keyframeString='@'+prefix+'keyframes '+animationName+' ';css3AnimationIsSupported=!0;break}}}
if(css3AnimationIsSupported){animationCss3Str=animationName+' '+o.duration/1000+'s '+o.delayBeforeStart/1000+'s infinite '+o.css3easing;$this.data('css3AnimationIsSupported',!0)}}
var _rePositionVertically=function(){$marqueeWrapper.css('transform','translateY('+(o.direction=='up'?containerHeight+'px':'-'+elHeight+'px')+')')},_rePositionHorizontally=function(){$marqueeWrapper.css('transform','translateX('+(o.direction=='left'?containerWidth+'px':'-'+elWidth+'px')+')')};if(o.duplicated){if(verticalDir){if(o.startVisible){$marqueeWrapper.css('transform','translateY(0)')}else{$marqueeWrapper.css('transform','translateY('+(o.direction=='up'?containerHeight+'px':'-'+((elHeight*2)-o.gap)+'px')+')')}}else{if(o.startVisible){$marqueeWrapper.css('transform','translateX(0)')}else{$marqueeWrapper.css('transform','translateX('+(o.direction=='left'?containerWidth+'px':'-'+((elWidth*2)-o.gap)+'px')+')')}}
if(!o.startVisible){loopCount=1}}else if(o.startVisible){loopCount=2}else{if(verticalDir){_rePositionVertically()}else{_rePositionHorizontally()}}
var animate=function(){if(o.duplicated){if(loopCount===1){o._originalDuration=o.duration;if(verticalDir){o.duration=o.direction=='up'?o.duration+(containerHeight/((elHeight)/o.duration)):o.duration*2}else{o.duration=o.direction=='left'?o.duration+(containerWidth/((elWidth)/o.duration)):o.duration*2}
if(animationCss3Str){animationCss3Str=animationName+' '+o.duration/1000+'s '+o.delayBeforeStart/1000+'s '+o.css3easing}
loopCount++}
else if(loopCount===2){o.duration=o._originalDuration;if(animationCss3Str){animationName=animationName+'0';keyframeString=$.trim(keyframeString)+'0 ';animationCss3Str=animationName+' '+o.duration/1000+'s 0s infinite '+o.css3easing}
loopCount++}}
if(verticalDir){if(o.duplicated){if(loopCount>2){$marqueeWrapper.css('transform','translateY('+(o.direction=='up'?0:'-'+elHeight+'px')+')')}
animationCss={'transform':'translateY('+(o.direction=='up'?'-'+elHeight+'px':0)+')'}}else if(o.startVisible){if(loopCount===2){if(animationCss3Str){animationCss3Str=animationName+' '+o.duration/1000+'s '+o.delayBeforeStart/1000+'s '+o.css3easing}
animationCss={'transform':'translateY('+(o.direction=='up'?'-'+elHeight+'px':containerHeight+'px')+')'};loopCount++}else if(loopCount===3){o.duration=o._completeDuration;if(animationCss3Str){animationName=animationName+'0';keyframeString=$.trim(keyframeString)+'0 ';animationCss3Str=animationName+' '+o.duration/1000+'s 0s infinite '+o.css3easing}
_rePositionVertically()}}else{_rePositionVertically();animationCss={'transform':'translateY('+(o.direction=='up'?'-'+($marqueeWrapper.height())+'px':containerHeight+'px')+')'}}}else{if(o.duplicated){if(loopCount>2){$marqueeWrapper.css('transform','translateX('+(o.direction=='left'?0:'-'+elWidth+'px')+')')}
animationCss={'transform':'translateX('+(o.direction=='left'?'-'+elWidth+'px':0)+')'}}else if(o.startVisible){if(loopCount===2){if(animationCss3Str){animationCss3Str=animationName+' '+o.duration/1000+'s '+o.delayBeforeStart/1000+'s '+o.css3easing}
animationCss={'transform':'translateX('+(o.direction=='left'?'-'+elWidth+'px':containerWidth+'px')+')'};loopCount++}else if(loopCount===3){o.duration=o._completeDuration;if(animationCss3Str){animationName=animationName+'0';keyframeString=$.trim(keyframeString)+'0 ';animationCss3Str=animationName+' '+o.duration/1000+'s 0s infinite '+o.css3easing}
_rePositionHorizontally()}}else{_rePositionHorizontally();animationCss={'transform':'translateX('+(o.direction=='left'?'-'+elWidth+'px':containerWidth+'px')+')'}}}
$this.trigger('beforeStarting');if(css3AnimationIsSupported){$marqueeWrapper.css(animationString,animationCss3Str);var keyframeCss=keyframeString+' { 100%  '+_objToString(animationCss)+'}',$styles=$marqueeWrapper.find('style');if($styles.length!==0){$styles.filter(":last").html(keyframeCss)}else{$('head').append('<style>'+keyframeCss+'</style>')}
_prefixedEvent($marqueeWrapper[0],"AnimationIteration",function(){$this.trigger('finished')});_prefixedEvent($marqueeWrapper[0],"AnimationEnd",function(){animate();$this.trigger('finished')})}else{$marqueeWrapper.animate(animationCss,o.duration,o.easing,function(){$this.trigger('finished');if(o.pauseOnCycle){_startAnimationWithDelay()}else{animate()}})}
$this.data('runningStatus','resumed')};$this.on('pause',methods.pause);$this.on('resume',methods.resume);if(o.pauseOnHover){$this.on('mouseenter',methods.pause);$this.on('mouseleave',methods.resume)}
if(css3AnimationIsSupported&&o.allowCss3Support){animate()}else{_startAnimationWithDelay()}})};$.fn.marquee.defaults={allowCss3Support:!0,css3easing:'linear',easing:'linear',delayBeforeStart:1000,direction:'left',duplicated:!1,duration:5000,speed:0,gap:20,pauseOnCycle:!1,pauseOnHover:!1,startVisible:!1}})(jQuery);

// 头部导航栏公告，文字超出滚动
// if($.cookie(COOKIENAME) === '1'){
//
// }
;void function () {
    // debugger;
    //头部存在通告，并且公告文字超出时才需要滚动
    var isScroll = false;
    var noticeBeyond = false;
    var notice = $('.top-nav-wrap .top-nav-container .text-marquee')[0] || $('.top-marquee-wrap .top-marquee-container .text-marquee')[0];
    var cookieName, cookieValue;
    if (notice) {
        noticeBeyond = $(notice).children('.marquee-container')[0].offsetWidth < $(notice).find('.text-content span')[0].offsetWidth;
        cookieName = $(notice).data('cookiename');
        cookieValue = $(notice).data('cookievalue');
    }
    isScroll = notice && noticeBeyond;
    if (isScroll) {
        $('.text-marquee .text-content').marquee({
            delayBeforeStart: 0,//开始动画的延迟时候，单位毫秒，默认为1000。
            duplicated: true,//是否复制文本。默认为false。
            pauseOnHover: true,//在hover时暂停跑马灯
            startVisible: true,//跑马灯在开始时是否可见。
            speed: 50,//pixels per second
        });
    }
    //关闭导航栏里的公告
    $('.J-header-nav-notice-close').click(function () {
        $(this).closest('.J-header-notice-in-nav').css({display: 'none'});
        if (cookieName) {
            $.cookie(cookieName, cookieValue, {expires: 9999});
        }
    });
    //关闭顶部公告
    $('.J-header-marquee-notice-close').click(function () {
        $(this).closest('.J-top-notice-index').css({display: 'none'});
        if (cookieName) {
            $.cookie(cookieName, cookieValue, {expires: 9999});
        }
    });
}.call(this);
;void function () {
    //关闭霸屏广告
    var closeCampaignPop = function(){
        var container = $('.J-campaign-pop-container');
        var cookieName = container.data('cookiename');
        var cookieValue = container.data('cookievalue');
        container.css({display: 'none'});
        $('.J-campaign-pop-mask').css({display: 'none'});
        if (cookieName) {
            $.cookie(cookieName, cookieValue, {expires: 9999, path: '/'});
        }
    };
    $('.J-campaign-pop-close').click(function () {
        closeCampaignPop();
    });
    $('.J-campaign-pop-container a').click(function(){
        if($(this)[0].href){
            closeCampaignPop();
        }
    });
    $(".J-campaign-pop-mask").click(function() {
        closeCampaignPop();
    });
    //关闭顶部banner
    $('.J-header-top-banner-close').click(function () {
        var cookieName = $(this).data('cookiename');
        var cookieValue = $(this).data('cookievalue');
        $(this).closest('.header-top-notice').css({display: 'none'});
        if (cookieName) {
            $.cookie(cookieName, cookieValue, {expires: 9999, path: '/'});
        }
    });
    //关闭顶部公告
    $('.J-header-top-notice-close').click(function () {
        var cookieName = $(this).data('cookiename');
        var cookieValue = $(this).data('cookievalue');
        $(this).closest('.header-announcement-touch').css({display: 'none'});
        if (cookieName) {
            $.cookie(cookieName, cookieValue, {expires: 9999, path: '/'});
        }
    });
}.call(this);

var SILK_ALEXANDRIA = {};

SILK_ALEXANDRIA.lanCode = $().val() || "0";

SILK_ALEXANDRIA.dialog400 = 400;
SILK_ALEXANDRIA.dialog500 = 500;
SILK_ALEXANDRIA.dialog550 = 550;
SILK_ALEXANDRIA.dialog750 = 750;
SILK_ALEXANDRIA.dialog760 = 760;
SILK_ALEXANDRIA.dialog780 = 780;

if($(window).width() < 767){
    SILK_ALEXANDRIA.dialog400 = 300;
    SILK_ALEXANDRIA.dialog500 = 300;
    SILK_ALEXANDRIA.dialog550 = 300;
    SILK_ALEXANDRIA.dialog750 = 300;
    SILK_ALEXANDRIA.dialog760 = 320;
    SILK_ALEXANDRIA.dialog780 = 320;
}

//埋点
function sendBIData(url) {
    var img = new Image();
    img.onload = function () {
        img = null;
    };
    img.src = url + "&time=" + new Date().valueOf();
}


//霸屏显示
$.ajax({
	url: '//www.crov.com/getScreenAds',
	type: 'get',
    dataType: 'jsonp',
    jsonp: 'jsoncallback',
	cache: false,
	success: function(res) {
		if (res.code === '10001') {
            var screenAds=JSON.parse(res.data)
			if (
                // 1是仅首页
                // 2是www下的
				(screenAds.adsLocation === '1' && $('#J-isFromHome').length > 0) ||
				screenAds.adsLocation === '2'
			) {
                $.cookie('fullPageBn', screenAds.adsId);
				showScreenImg(screenAds.adsLink, screenAds.screenPic);
			}
		}
	},
	error: function() {}
});

function showScreenImg(aLink,imgSrc) {
    var fullPageBn =
    '<div class="full-page-bn J-full-page-bn">' +
        '<div class="full-page-bn-cnt">' +
            '<a href="' + aLink + '" class="J-full-page-link">' +
                '<img src="' + imgSrc + '" alt="">' +
            '</a>' +
            '<div class="full-page-bn-close J-full-page-bn-close">' +
                '<i class="ob-icon icon-delete"></i>' +
            '</div>' +
        '</div>' +
    '</div>';
    $('body').append(fullPageBn);
    $('.J-full-page-link').on('click', function(e) {
        e.stopPropagation();
    });
    $('.J-full-page-bn').on('click', function() {
        $('.J-full-page-bn').hide();
    });
}
